import React from "react";
import Header from "components/header";
import Calendar from "components/calendar";

export default function PeriodTracker() {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const breadcrumbs = [
    {
      name: "Period Tracker",
      link: "/PeriodTracker",
      active: true,
    },
  ];

  const changeDate = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <React.Fragment>
      <Header
        title="Period Tracker | Uncovering Intimacy"
        h1="Period Tracker"
        breadcrumbs={breadcrumbs}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Calendar selectedDate={selectedDate} changeDate={changeDate} />
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
}
