import { UserContext } from "App";
import { useSessions } from "hooks/useOurSexplorationListSessions";
import React, { useContext } from "react";
import { otherSpouse } from "./_Functions";
import LinkButton from "components/linkbutton";
import Card, { CardBody, CardHeading } from "components/card";

type NoMoreQuestionsProps = {
  spouse: string;
};

export default function NoMoreQuestions(props: NoMoreQuestionsProps) {
  const user = useContext(UserContext);
  const { spouse } = props;
  const { getSessionById } = useSessions();

  const session_id = user.oursexplorationlist_current_session_id;
  const session = getSessionById(session_id);
  let status = "";
  if (session !== undefined) {
    if (spouse.toLowerCase() === "wife") {
      status = session.husband_status;
    } else {
      status = session.wife_status;
    }
  }

  const getOutcome = () => {
    if (status === "100%") {
      return (
        <React.Fragment>
          <Card>
            <CardHeading>Both spouses completed</CardHeading>
            <CardBody>
              It looks like your {otherSpouse(spouse)} has completed the list as
              well, so the next step is to review your answers and discuss them
              together.
              <LinkButton
                to="/OurSexplorationList/FindMatches"
                className="btn btn-primary"
              >
                Find Matches
              </LinkButton>
            </CardBody>
          </Card>
        </React.Fragment>
      );
    } else if (status === "not_started") {
      return (
        <React.Fragment>
          <Card>
            <CardHeading>Spouse started yet</CardHeading>
            <CardBody>
              It looks like your {otherSpouse(spouse)} hasn't started the list
              yet. If you haven't already, send them a link to the app and let
              them know that you're ready to start exploring together. Once
              they've started filling out their answers, you can come back to
              view your matches.
            </CardBody>
          </Card>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeading>No more questions</CardHeading>
        <CardBody>
          Congratulations! Finishing all the questions in Our Sexploration List
          is a great achievement, and it shows your commitment to improving your
          marriage. The fact that you were able to engage in open and honest
          communication about your sexual desires is a testament to your
          dedication to exploring new ways to connect with your spouse. Keep up
          the good work, and may your continued efforts bring even more intimacy
          and fulfillment to your relationship.
        </CardBody>
      </Card>

      {getOutcome()}
    </React.Fragment>
  );
}
