import React from "react";
import { getColors } from "./_globalFunctions";

export default function MoreInfo(props: {
  children: React.ReactNode;
  color?: string;
  className?: string | "";
  description?: string;
}) {
  let color = props.color || "gray";
  let { lightColor, ringColor, darkTextColor } = getColors(color);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <span
        onClick={() => setOpen(!open)}
        className={
          "inline-flex items-center rounded-md cursor-pointer " +
          lightColor +
          " px-2 py-1 text-xs font-medium " +
          darkTextColor +
          " ring-1 ring-inset " +
          ringColor +
          " " +
          props.className
        }
      >
        {props.children}
      </span>
      <p>{open && props.description}</p>
    </React.Fragment>
  );
}
