import React, { useContext } from "react";
import { makeQuestionTypeReadable, Response } from "types/OurSexplorationList";
import { getDescription, makeAnswerReadable } from "./_Functions";
import { UserContext } from "App";
import Card, { CardBody, CardHeading } from "components/card";
import MoreInfo from "components/moreInfo";
import LinkButton from "components/linkbutton";

export default function ShowMatchType(props: any) {
  const { matches, type, heading, open, toggle, children, color } = props;

  const user = useContext(UserContext);

  const match = matches[type];

  if (Object.keys(match).length === 0) return null;

  return (
    <div className={"mb-5 " + color}>
      <Card color={color}>
        <CardHeading>{heading}</CardHeading>
        <CardBody>
          {children}
          {match !== undefined &&
            Object.keys(match).map((questionType) => (
              <Card key={questionType + "|" + type} color={""}>
                <CardHeading>
                  {makeQuestionTypeReadable(questionType)}{" "}
                  <MoreInfo
                    color="grey"
                    description={getDescription(questionType)}
                  >
                    more info
                  </MoreInfo>
                </CardHeading>
                <CardBody>
                  {match[questionType].map((match: Response) => (
                    <div key={match.question}>
                      {heading == "Errors"
                        ? "Missing match: " + match.husband + " | " + match.wife
                        : null}
                      <h4>
                        {match.paired_question !== undefined
                          ? match.question
                              .replaceAll("{spouse}", "wife")
                              .replaceAll("{their}", "her") +
                            " / " +
                            match.paired_question
                              .replaceAll("{spouse}", "husband")
                              .replaceAll("{their}", "his")
                          : match.question
                              .replaceAll("{spouse}", "wife")
                              .replaceAll("{their}", "her")}
                      </h4>
                      <div className="mt-2">
                        <strong className="inline-block w-24">Husband: </strong>
                        {makeAnswerReadable(
                          match.husband,
                          questionType,
                          user.oursexplorationlist_simplified_version
                        ) ||
                          makeAnswerReadable(
                            match.husband,
                            questionType,
                            user.oursexplorationlist_simplified_version === 1
                              ? 0
                              : 1
                          )}
                        <LinkButton
                          className="ms-2"
                          to={
                            "/OurSexplorationList/AnswerQuestions/Specific/husband/" +
                            match.questionId +
                            "/Matches"
                          }
                        >
                          Edit
                        </LinkButton>
                        {match.husband_notes ? (
                          <React.Fragment>
                            <br />
                            <em>
                              <b>Note:</b> {match.husband_notes}
                            </em>
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className="mt-2">
                        <strong className="inline-block w-24">Wife: </strong>
                        {makeAnswerReadable(
                          match.wife,
                          questionType,
                          user.oursexplorationlist_simplified_version
                        ) ||
                          makeAnswerReadable(
                            match.wife,
                            questionType,
                            user.oursexplorationlist_simplified_version === 1
                              ? 0
                              : 1
                          )}
                        <LinkButton
                          className="ms-2"
                          to={
                            "/OurSexplorationList/AnswerQuestions/Specific/wife/" +
                            (match.paired_questionId || match.questionId) +
                            "/Matches"
                          }
                        >
                          Edit
                        </LinkButton>

                        {match.wife_notes ? (
                          <React.Fragment>
                            <br />
                            <em>
                              <b>Note:</b> {match.wife_notes}
                            </em>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            ))}
        </CardBody>
      </Card>
    </div>
  );
}
