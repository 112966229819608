import { gql, GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { APIResponse } from "types/APIResponse";
import { User } from "types/Users";

export function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState() as any;
  const [loginError, setLoginError] = useState("");
  const [purchases, setPurchases] = useState([]) as any;
  const [isAdmin, setIsAdmin] = useState(false);

  let graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_URL + "/graphql",
    {
      headers: {
        authorization: "JWT " + (user !== undefined ? user.token : ""),
      },
    }
  );

  useEffect(() => {
    if (user === undefined) {
      const jsonUser = secureLocalStorage.getItem("wordpress-user") as string;
      if (jsonUser) {
        const userData = JSON.parse(jsonUser);
        setUser(userData.user);
        setIsLoggedIn(true);
        setPurchases(userData.purchases);
        if (userData.user.roles.includes("administrator")) {
          setIsAdmin(true);
        }
      }
    }
  }, [user]);

  const refreshLocalData = async () => {
    await getAppUserData(user.ID).then((userData) => {
      const newData = { ...user, ...userData };
      setUser(newData);

      secureLocalStorage.setItem(
        "wordpress-user",
        JSON.stringify({ user: newData, purchases: purchases })
      );
    });
  };

  async function getAppUserData(user_id: number): Promise<User> {
    const query = gql`
      query getUser($user_id: Int!) {
        getUser(user_id: $user_id) {
          user_id
          created_at
          updated_at
          oursexplorationlist_current_session_id
          excluded_topics
          oursexplorationlist_simplified_version
        }
      }
    `;
    const graphResponse: any = await graphQLClient.request(query, { user_id });
    const userData = graphResponse.getUser;
    if (userData.length === 0) {
      await createAppUser(user_id);
      return getAppUserData(user_id);
    } else {
      return userData[0];
    }
  }

  const createAppUser = async (user_id: number) => {
    const query = gql`
      mutation createUser($user_id: Int!) {
        createUser(user_id: $user_id) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const graphResponse: any = await graphQLClient.request(query, { user_id });
    const response: APIResponse = graphResponse;

    return response.id;
  };

  const login = async (username: string, password: string) => {
    setLoginError("");
    setUser({});
    setIsLoggedIn(false);
    const response = await fetch(process.env.REACT_APP_API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    const { success, data } = await response.json();
    if (success) {
      graphQLClient = new GraphQLClient(
        process.env.REACT_APP_API_URL + "/graphql",
        {
          headers: {
            authorization: "JWT " + data.user.token,
          },
        }
      );

      const appUserData = await getAppUserData(parseInt(data.user.ID));
      const totalUser = { ...data.user, ...appUserData };
      setIsLoggedIn(true);
      setUser(totalUser);
      secureLocalStorage.setItem(
        "wordpress-user",
        JSON.stringify({
          user: totalUser,
          purchases: data.purchases,
        })
      );
      if (data.user.roles.includes("administrator")) {
        setIsAdmin(true);
      }
      return true;
    } else {
      setIsLoggedIn(false);
      setUser({});
      setLoginError("Username or password is incorrect.");
      return false;
    }
  };

  const logout = () => {
    if (isLoggedIn) {
      secureLocalStorage.removeItem("wordpress-user");
      setIsLoggedIn(false);
      setUser({});
    }
  };

  const updateOurSexplorationListCurrentSessionID = async (
    oursexplorationlist_current_session_id: number
  ) => {
    const query = gql`
      mutation updateUserSexplorationListCurrentSession(
        $user_id: Int!
        $oursexplorationlist_current_session_id: ID!
      ) {
        updateUserSexplorationListCurrentSession(
          user_id: $user_id
          oursexplorationlist_current_session_id: $oursexplorationlist_current_session_id
        ) {
          success
          message
          affectedRows
          id
        }
      }
    `;

    const graphResponse: any = await graphQLClient.request(query, {
      user_id: user.ID,
      oursexplorationlist_current_session_id,
    });
    const response: APIResponse = graphResponse;

    await refreshLocalData();

    return response;
  };

  const updateUserSettings = async (
    excluded_topics: string,
    oursexplorationlist_simplified_version: number
  ) => {
    const query = gql`
      mutation updateUserSettings(
        $user_id: Int!
        $excluded_topics: String!
        $oursexplorationlist_simplified_version: Int!
      ) {
        updateUserSettings(
          user_id: $user_id
          excluded_topics: $excluded_topics
          oursexplorationlist_simplified_version: $oursexplorationlist_simplified_version
        ) {
          success
          message
          affectedRows
          id
        }
      }
    `;

    const graphResponse: any = await graphQLClient.request(query, {
      user_id: user.ID,
      excluded_topics,
      oursexplorationlist_simplified_version,
    });
    const response: APIResponse = graphResponse.updateUserSettings;

    await refreshLocalData();

    return response;
  };

  if (isLoggedIn && user && isTokenExpired(user.token)) {
    logout();
  }

  return {
    login,
    logout,
    refreshLocalData,
    isLoggedIn,
    user,
    purchases,
    loginError,
    updateOurSexplorationListCurrentSessionID,
    updateUserSettings,
    isAdmin,
  };
}

function isTokenExpired(token: string) {
  try {
    // JWT tokens are base64Url encoded
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const { exp } = JSON.parse(jsonPayload);
    if (!exp) {
      // Token doesn't have an expiration date, consider it expired
      return true;
    }

    const currentTimestamp = Math.floor(Date.now() / 1000); // in seconds
    return exp < currentTimestamp;
  } catch (e) {
    // If an error occurs while decoding the token, consider it expired
    return true;
  }
}
