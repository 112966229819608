import { UserContext } from "App";
import { gql, GraphQLClient } from "graphql-request";
import { Score } from "pages/SpontaneousResponsiveQuiz";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Result } from "types/Results";

export function useSpontaneousResponsiveDesireResults() {
  const user = useContext(UserContext);
  const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_URL + "/graphql/spontaneousresponsivequiz",
    {
      headers: {
        authorization: "JWT " + user.token,
      },
    }
  );

  const results = useQuery(["spontaneousresponsive", user.ID], async () => {
    if (user.ID !== 0) {
      const query = gql`
        query getMyResults($user_id: ID!) {
          getMyResults(user_id: $user_id) {
            user_id
            spouse
            responsiveScore
            spontaneousScore
            created_at
            updated_at
          }
        }
      `;
      const { getMyResults }: { getMyResults: Result[] } =
        await graphQLClient.request(query, {
          user_id: user.ID,
        });

      return getMyResults;
    } else {
      return {} as Result[];
    }
  });

  const saveResult = async (score: Score) => {
    if (
      results.data?.find((result) => result.spouse === score.spouse) !==
      undefined
    ) {
      // update existing record
      const query = gql`
        mutation updateResult(
          $user_id: Int!
          $spouse: String!
          $responsiveScore: Float!
          $spontaneousScore: Float!
        ) {
          updateResult(
            user_id: $user_id
            spouse: $spouse
            responsiveScore: $responsiveScore
            spontaneousScore: $spontaneousScore
          ) {
            success
            message
            affectedRows
            id
          }
        }
      `;
      const { updateResult }: { updateResult: Response } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          spouse: score.spouse,
          responsiveScore: score.responsiveScore,
          spontaneousScore: score.spontaneousScore,
        });

      results.refetch();
      return updateResult;
    } else {
      // create new record
      const query = gql`
        mutation createResult(
          $user_id: Int!
          $spouse: String!
          $responsiveScore: Float!
          $spontaneousScore: Float!
        ) {
          createResult(
            user_id: $user_id
            spouse: $spouse
            responsiveScore: $responsiveScore
            spontaneousScore: $spontaneousScore
          ) {
            success
            message
            affectedRows
            id
          }
        }
      `;
      const { createResult }: { createResult: Response } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          spouse: score.spouse,
          responsiveScore: score.responsiveScore,
          spontaneousScore: score.spontaneousScore,
        });

      results.refetch();
      return createResult;
    }
  };

  return { results, saveResult };
}
