import React, { useCallback, useContext } from "react";
import { getFullPath, Question, question_types } from "types/Questions";
import { useOurSexplorationListAnswers } from "hooks/useOurSexplorationListAnswers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AnswerButton, getButtons, getDescription } from "./_Functions";
import { Field, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { UserContext } from "App";
import Card, { CardBody, CardHeading } from "components/card";
import Button from "components/button";

export default function ShowQuestion({
  spouse,
  question,
  refetch,
  redirectUrl,
}: {
  spouse: string;
  question: Question;
  refetch: Function;
  redirectUrl?: string;
}) {
  const user = useContext(UserContext);
  const { createAnswer, answers } = useOurSexplorationListAnswers();
  const navigate = useNavigate();

  let notes = "";
  if (answers.isSuccess) {
    notes =
      answers.data.find(
        (answer) =>
          answer.question_id == question.id &&
          answer.spouse == spouse.toLowerCase() &&
          answer.user_id == user.ID &&
          answer.session_id == user.oursexplorationlist_current_session_id
      )?.notes || "";
  }

  const getAnswerButtons = (setFieldValue: Function, values: any) => {
    let buttons: AnswerButton[] = [];

    buttons = getButtons(
      question.question_type,
      buttons,
      user.oursexplorationlist_simplified_version
    );

    return (
      <React.Fragment>
        <div className="grid grid-cols-12 gap-1">
          {buttons.map((button) => {
            return (
              <Button
                name="answer"
                style={{
                  backgroundColor: button.backgroundcolor,
                  color: button.color,
                  borderColor:
                    values.answer == button.value ? "black" : "white",
                  borderStyle: "solid",
                  borderWidth: "5px",
                }}
                className={"text-lg " + button.cols}
                value={button.value}
                data-question-id={question.id}
                onClick={(event) => {
                  const buttonElement = event.target as HTMLButtonElement;
                  setFieldValue("answer", button.value);
                  event.preventDefault();
                }}
                key={button.value}
              >
                {button.text}
              </Button>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const handleFormSubmit = useCallback(
    (values: any, formikHelper: FormikHelpers<any>) => {
      if (values.answer == "") {
        toast("Please select an answer", {
          type: "error",
        });
      } else {
        createAnswer(
          parseInt(question.id.toString()),
          values.answer,
          spouse.toLowerCase(),
          values.notes
        ).then((response) => {
          toast(response.message, {
            type: response.success ? "success" : "error",
          });
          if (response.success) {
            formikHelper.resetForm();
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              refetch();
            }
          }
        });
      }
    },
    [createAnswer, refetch, spouse, navigate, redirectUrl, question]
  );

  function processQuestion(question: Question) {
    let questionLabel = question.question.replaceAll(
      "{spouse}",
      spouse.toLowerCase() === "wife" ? "husband" : "wife"
    );

    questionLabel = questionLabel.replaceAll(
      "{their}",
      spouse.toLowerCase() === "wife" ? "his" : "her"
    );

    switch (question.question_type) {
      case "positions":
        return "The position " + questionLabel;
      case "rear":
      case "ejaculate":
      case "testicles":
      case "female_genitals":
      case "breasts":
      case "male_genitals":
      case "attributes":
      case "activities":
        return 'The word "' + questionLabel + '"';
      case "feelings":
        return "Feeling " + questionLabel;
      default:
        return questionLabel;
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeading>
          {getFullPath(question.question_type, question_types)}
        </CardHeading>
        <CardBody>{getDescription(question.question_type)}</CardBody>
      </Card>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ notes: notes, answer: "" }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Card>
              <CardHeading>{processQuestion(question)}</CardHeading>
              <CardBody>
                {getAnswerButtons(setFieldValue, values)}
                <div className="mt-2">Notes:</div>
                <Field name="notes" as="textarea" className="w-full" />
                <Button className="w-full mt-3 h-16 text-xl">Next</Button>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
