import Badge from "components/badge";
import Card, { CardBody, CardHeading } from "components/card";
import Header from "components/header";
import LinkButton from "components/linkbutton";
import { useAuth } from "hooks/useAuth";
import { useSessions } from "hooks/useOurSexplorationListSessions";
import React, { useEffect } from "react";
import { APIResponse } from "types/APIResponse";
import { Session } from "types/Sessions";

export default function OurSexplorationList() {
  const { user, isAdmin, updateOurSexplorationListCurrentSessionID } =
    useAuth();
  const {
    sessions,
    createSession,
    getSessionById,
    getBadgeColor,
    makeStatusReadable,
  } = useSessions();
  const [currentSession, setCurrentSession] = React.useState<Session>();

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: true,
    },
  ];

  useEffect(() => {
    if (user !== undefined) {
      if (user.oursexplorationlist_current_session_id === null) {
        createSession("First session").then((response: APIResponse) => {
          if (response.success) {
            updateOurSexplorationListCurrentSessionID(response.id);
          }
        });
      }
    }
  }, [user, createSession, updateOurSexplorationListCurrentSessionID]);

  if (
    user !== undefined &&
    user.oursexplorationlist_current_session_id !== null &&
    currentSession === undefined &&
    sessions.isSuccess
  ) {
    const currentSessionID: number =
      user.oursexplorationlist_current_session_id;
    const session = getSessionById(currentSessionID);
    setCurrentSession(session);
  }

  if (currentSession === undefined) {
    return (
      <React.Fragment>
        <Header
          title="Find Matches | Our Sexploration List | Uncovering Intimacy"
          h1="Find Matches"
          breadcrumbs={breadcrumbs}
        >
          Loading...
        </Header>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Header
          title="Our Sexploration List | Uncovering Intimacy"
          h1="Our Sexploration List"
          breadcrumbs={breadcrumbs}
        >
          {isAdmin && (
            <LinkButton
              to="/OurSexplorationList/ManageQuestions"
              color="red"
              className="me-2"
            >
              Manage Questions
            </LinkButton>
          )}
          <LinkButton color="grey" to="/OurSexplorationList/Directions">
            Directions
          </LinkButton>

          <Card>
            <CardHeading>
              <React.Fragment>
                {currentSession.notes}
                <LinkButton
                  to="/OurSexplorationList/Sessions"
                  className="float-right"
                >
                  Manage sessions
                </LinkButton>
              </React.Fragment>
            </CardHeading>
            <CardBody>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 clear-both">
                <div className="border border-gray-300 rounded-lg p-4">
                  <h1 className="text-lg font-medium mb-2">
                    Husband
                    <Badge
                      className="float-right"
                      color={getBadgeColor(currentSession.husband_status)}
                    >
                      {makeStatusReadable(currentSession.husband_status)}
                    </Badge>
                  </h1>

                  <LinkButton
                    to="/OurSexplorationList/AnswerQuestions/Husband"
                    color="blue"
                    className="w-full block text-center mb-2"
                  >
                    Answer as Husband
                  </LinkButton>
                  <LinkButton
                    to="/OurSexplorationList/ViewAnswers/Husband"
                    color="blue"
                    className="w-full block text-center"
                  >
                    View Husband's Answers
                  </LinkButton>
                </div>
                <div className="border border-gray-300 rounded-lg p-4">
                  <h1 className="text-lg font-medium mb-2">
                    Wife
                    <Badge
                      className="float-right"
                      color={getBadgeColor(currentSession.wife_status)}
                    >
                      {makeStatusReadable(currentSession.wife_status)}
                    </Badge>
                  </h1>

                  <div>
                    <LinkButton
                      to="/OurSexplorationList/AnswerQuestions/Wife"
                      color="pink"
                      className="w-full block text-center mb-2"
                    >
                      Answer as Wife
                    </LinkButton>
                  </div>
                  <LinkButton
                    to="/OurSexplorationList/ViewAnswers/Wife"
                    color="pink"
                    className="w-full block text-center"
                  >
                    View Wife's Answers
                  </LinkButton>
                </div>

                <div className="border border-gray-300 rounded-lg p-4">
                  <h1 className="text-lg font-medium mb-2">Find Matches:</h1>
                  <LinkButton
                    to="/OurSexplorationList/FindMatches"
                    color="red"
                    className="w-full block text-center"
                  >
                    Find Matches
                  </LinkButton>
                </div>
              </div>
            </CardBody>
          </Card>
        </Header>
      </React.Fragment>
    );
  }
}
