import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
//import "./assets/scss/custom.scss";
import Layout from "./components/Layout";
import React from "react";
import { useAuth } from "./hooks/useAuth";
import OurSexplorationList from "pages/OurSexplorationList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sessions from "pages/OurSexplorationList/Sessions";
import Directions from "pages/OurSexplorationList/Directions";
import ManageQuestions from "pages/OurSexplorationList/ManageQuestions";
import AnswerQuestions from "pages/OurSexplorationList/AnswerQuestions";
import RandomQuestions from "pages/OurSexplorationList/RandomQuestion";
import QuestionsInOrder from "pages/OurSexplorationList/QuestionsInOrder";
import QuestionsSpouseAnswered from "pages/OurSexplorationList/QuestionSpouseAnswered";
import SpontaneousResponsiveQuiz from "pages/SpontaneousResponsiveQuiz";
import FindMatches from "pages/OurSexplorationList/FindMatches";
import SpecificQuestion from "pages/OurSexplorationList/SpecificQuestion";
import QuestionsAlreadyAnswered from "pages/OurSexplorationList/QuestionsAlreadyAnswered";
import ViewAnswers from "pages/OurSexplorationList/ViewAnswers";
import PeriodTracker from "pages/PeriodTracker";
import Settings from "pages/Settings";
import Logout from "pages/Logout";
import QuestionsBySection from "pages/OurSexplorationList/QuestionsBySection";

export const UserContext = React.createContext({
  ID: 0,
  oursexplorationlist_current_session_id: 0,
  token: "",
  oursexplorationlist_simplified_version: 0,
});

function App() {
  const { isLoggedIn, user } = useAuth();

  return (
    <React.Fragment>
      <UserContext.Provider value={user}>
        <Layout>
          {isLoggedIn ? (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Logout" element={<Logout />} />
              <Route path="/Settings" element={<Settings />} />
              <Route
                path="/OurSexplorationList"
                element={<OurSexplorationList />}
              />
              <Route
                path="/OurSexplorationList/Sessions"
                element={<Sessions />}
              />
              <Route
                path="/OurSexplorationList/Directions"
                element={<Directions />}
              />
              <Route
                path="/OurSexplorationList/ManageQuestions"
                element={<ManageQuestions />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/:spouse"
                element={<AnswerQuestions />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/Random/:spouse"
                element={<RandomQuestions />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/InOrder/:spouse"
                element={<QuestionsInOrder />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/BySection/:section/:spouse"
                element={<QuestionsBySection />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/SpouseAnswered/:spouse"
                element={<QuestionsSpouseAnswered />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/Specific/:spouse/:questionId/:returnTo"
                element={<SpecificQuestion />}
              />
              <Route
                path="/OurSexplorationList/AnswerQuestions/AlreadyAnswered/:spouse"
                element={<QuestionsAlreadyAnswered />}
              />
              <Route
                path="/OurSexplorationList/ViewAnswers/:spouse"
                element={<ViewAnswers />}
              />
              <Route
                path="/OurSexplorationList/FindMatches"
                element={<FindMatches />}
              />
              <Route
                path="/SpontaneousResponsiveQuiz"
                element={<SpontaneousResponsiveQuiz />}
              />
              <Route path="/PeriodTracker" element={<PeriodTracker />} />
            </Routes>
          ) : (
            <Login />
          )}
          <div className="mb-5"></div>

          <ToastContainer autoClose={3000} />
        </Layout>
      </UserContext.Provider>
    </React.Fragment>
  );
}

export default App;
