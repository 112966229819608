import Header from "components/header";
import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { quizQuestions } from "./quizQuestions";
import * as Yup from "yup";
import { ScrollToFieldError } from "components/scrollToFieldError";
import { useSpontaneousResponsiveDesireResults } from "hooks/useSpontaneousResponsiveDesireResults";
import { Spectrum } from "./_spectrum";
import { UseQueryResult } from "react-query";
import { Result } from "types/Results";
import { RadioButtonGroup } from "components/RadioButtonGroup";
import { ErrorAlert } from "components/alerts";

export type Score = {
  spouse: string;
  responsiveScore: number;
  spontaneousScore: number;
};

export default function SpontaneousResponsiveQuiz() {
  const { results, saveResult } = useSpontaneousResponsiveDesireResults();
  const [score, setScore] = React.useState<Score | undefined>(undefined);

  const scoreQuiz = useCallback((values: any, formik: any) => {
    let score: Score = {
      responsiveScore: 0,
      spontaneousScore: 0,
      spouse: values.spouse,
    };

    quizQuestions.forEach((question, index) => {
      let answer = parseInt(eval("values." + "question" + index));
      if (question.type === "responsive") {
        score.responsiveScore += answer;
      } else {
        score.spontaneousScore += answer;
      }
    });
    score.responsiveScore /= quizQuestions.filter(
      (question) => question.type === "responsive"
    ).length;
    score.spontaneousScore /= quizQuestions.filter(
      (question) => question.type === "spontaneous"
    ).length;

    setScore(score);

    saveResult(score);
  }, []);

  const validationSchema = useCallback(() => {
    let shape = Yup.object().shape({
      spouse: Yup.string().required("Required"),
    });
    quizQuestions.forEach((question, index) => {
      shape = shape.concat(
        Yup.object().shape({
          ["question" + index]: Yup.string().required("Required"),
        })
      );
    });

    return shape;
  }, []);

  const showSpectrums = useCallback(
    (results: UseQueryResult<Result[], unknown>) => {
      if (results.isSuccess) {
        return (
          <React.Fragment>
            <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-2">
              {results.data.map((result: Score) => {
                return (
                  <React.Fragment>
                    <div
                      key={result.spouse}
                      className=" flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                    >
                      <h2>
                        {result.spouse.charAt(0).toUpperCase() +
                          result.spouse.slice(1)}{" "}
                      </h2>
                      <dt className="text-sm font-medium leading-6 text-gray-500 mt-4 mb-2">
                        Responsive Desire
                      </dt>
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        <div className="flex justify-between mb-1">
                          <span className="text-base font-medium text-blue-700 ">
                            Never
                          </span>
                          <span className="text-sm font-medium text-blue-700 ">
                            Very Often
                          </span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-6 rounded-full"
                            style={{
                              width: (result.responsiveScore - 1) * 33.33 + "%",
                            }}
                          ></div>
                        </div>
                      </dd>
                      <dt className="text-sm font-medium leading-6 text-gray-500 mt-4 mb-2">
                        Spontaneous Desire
                      </dt>
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        <div className="flex justify-between mb-1">
                          <span className="text-base font-medium text-blue-700 ">
                            Never
                          </span>
                          <span className="text-sm font-medium text-blue-700 ">
                            Very Often
                          </span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-6 rounded-full"
                            style={{
                              width:
                                (result.spontaneousScore - 1) * 33.33 + "%",
                            }}
                          ></div>
                        </div>
                      </dd>
                    </div>
                  </React.Fragment>
                );
              })}
            </dl>
          </React.Fragment>
        );
      } else {
        return null;
      }
    },
    []
  );

  const resetScore = useCallback(() => {
    setScore(undefined);
  }, []);

  const outputScore = useCallback(() => {
    let responsiveMessage: string = "";
    let spontaneousMessage: string = "";

    if (score === undefined) {
      return null;
    } else {
      if (score.spontaneousScore < 2) {
        spontaneousMessage =
          "You don't appear to experience spontaneous desire often";
      } else if (score.spontaneousScore < 3) {
        spontaneousMessage =
          "You appear to experience spontaneous desire somewhat often";
      } else {
        spontaneousMessage =
          "You appear to experience spontaneous desire very often";
      }
      if (score.responsiveScore < 2) {
        responsiveMessage =
          "You don't appear to experience responsive desire often";
      } else if (score.responsiveScore < 3) {
        responsiveMessage =
          "You appear to experience responsive desire somewhat often";
      } else {
        responsiveMessage =
          "You appear to experience responsive desire very often";
      }
    }

    return (
      <React.Fragment>
        <h2>Responsive desire</h2>
        {responsiveMessage}
        <Spectrum
          value={(score.responsiveScore - 1) * 33.33}
          leftSideLabel="Never"
          rightSideLabel="Very often"
        />
        <h2 className="mt-2">Spontaneous desire</h2>
        {spontaneousMessage}
        <Spectrum
          value={(score.spontaneousScore - 1) * 33.33}
          leftSideLabel="Never"
          rightSideLabel="Very often"
        />
        <div className="mt-2">
          For more information on responsive and spontaneous desire, you might
          be interested in checking out these posts:
        </div>
        <ul className="mt-2">
          <li>
            <a
              href="https://www.uncoveringintimacy.com/responsive-vs-spontaneous-desire/"
              target="_blank"
            >
              Responsive vs Spontaneous Desire
            </a>
          </li>
          <li>
            <a
              href="https://www.uncoveringintimacy.com/swm-047-spontaneous-desire-is-a-blessing/"
              target="_blank"
            >
              Spontaneous desire is a blessing
            </a>
          </li>
          <li>
            <a
              href="https://www.uncoveringintimacy.com/responsive-desire-is-a-blessing/"
              target="_blank"
            >
              Responsive desire is a blessing
            </a>
          </li>
        </ul>
        <Button color="primary" onClick={resetScore}>
          Take the quiz again
        </Button>
      </React.Fragment>
    );
  }, [score]);

  return (
    <React.Fragment>
      <Header
        title="Spontaneous Responsive Quiz | Uncovering Intimacy"
        h1="Spontaneous Responsive Quiz"
        breadcrumbs={[
          {
            name: "Spontaneous Responsive Quiz",
            link: "/spontaneous-responsive-quiz",
            active: true,
          },
        ]}
      >
        {score !== undefined ? (
          outputScore()
        ) : (
          <React.Fragment>
            {results.isSuccess && results.data.length > 0 && (
              <React.Fragment>
                <h2>Previous scores:</h2>
                <Row>{showSpectrums(results)}</Row>
              </React.Fragment>
            )}
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white">
              <h2 className="mt-2">Take quiz</h2>
              <Formik
                onSubmit={scoreQuiz}
                initialValues={{ spouse: "" }}
                validationSchema={validationSchema}
              >
                {({ values, errors }: { values: any; errors: any }) => {
                  console.log(values, errors);
                  return (
                    <Form>
                      <ScrollToFieldError />
                      <RadioButtonGroup
                        name="spouse"
                        title="I am answering as the"
                        options={[
                          {
                            id: "spouseHusband",
                            value: "husband",
                            text: "Husband",
                            checked: () => values.spouse == "husband",
                          },
                          {
                            id: "spouseWife",
                            value: "wife",
                            text: "Wife",
                            checked: () => values.spouse == "wife",
                          },
                        ]}
                      />
                      {quizQuestions.map((question, index) => {
                        return (
                          <RadioButtonGroup
                            key={index}
                            name={"question" + index}
                            title={question.question}
                            options={question.answers.map((answer, index2) => {
                              return {
                                id:
                                  "question" +
                                  index +
                                  "answer" +
                                  index2.toString(),
                                value: (index2 + 1).toString(),
                                text: answer,
                                checked: () =>
                                  eval("values." + "question" + index) ===
                                  (index2 + 1).toString(),
                              };
                            })}
                          />
                        );
                      })}
                      <Button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-4"
                      >
                        Submit
                      </Button>

                      {errors.length > 0 && (
                        <ErrorAlert message="All questions must be answered to submit the quiz" />
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </React.Fragment>
        )}
      </Header>
    </React.Fragment>
  );
}
