import Header from "components/header";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Question } from "types/Questions";
import { useAuth } from "hooks/useAuth";
import { getQuestionOutput } from "./_Functions";

export default function RandomQuestions(props: any) {
  const { logout } = useAuth();
  const params = useParams();
  const spouse: string = params.spouse || "";
  const [question, setQuestion] = React.useState<Question | undefined>(
    undefined
  );
  const { unansweredQuestions } = useOurSexplorationListQuestions(
    spouse.toLowerCase()
  );

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: false,
    },
    {
      name: "Random Questions",
      link: "/OurSexplorationList/AnswerQuestions/Random/" + spouse,
      active: true,
    },
  ];

  useEffect(() => {
    if (unansweredQuestions.isSuccess) {
      setQuestion(
        unansweredQuestions.data[
          Math.floor(Math.random() * unansweredQuestions.data.length)
        ]
      );
    }
  }, [unansweredQuestions.data, unansweredQuestions.isSuccess]);

  const refetch = async () => {
    await unansweredQuestions.refetch();
  };

  return (
    <React.Fragment>
      <Header
        title="Random Questions | Our Sexploration List | Uncovering Intimacy"
        h1="Random Questions"
        breadcrumbs={breadcrumbs}
      >
        {getQuestionOutput(
          question,
          spouse,
          refetch,
          unansweredQuestions,
          logout
        )}
      </Header>
    </React.Fragment>
  );
}
