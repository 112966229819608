import Card, { CardBody, CardHeading } from "components/card";
import Header from "components/header";
import React from "react";

export default function Directions(props: any) {
  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Our Sexploration List",
      link: "/oursexplorationlist",
      active: false,
    },
    {
      name: "Directions",
      link: "/oursexplorationlist/directions",
      active: true,
    },
  ];

  return (
    <React.Fragment>
      <Header
        title="Directions | Our Sexploration List | Uncovering Intimacy"
        h1="Directions"
        breadcrumbs={breadcrumbs}
      >
        <Card>
          <CardHeading>Directions</CardHeading>
          <CardBody>
            Directions will go here
            {/* TODO: Write Directions */}
          </CardBody>
        </Card>
      </Header>
    </React.Fragment>
  );
}
