import React from "react";
import ShowQuestion from "./_ShowQuestion";
import { Question } from "types/Questions";
import { UseQueryResult } from "react-query";
import NoMoreQuestions from "./_NoMoreQuestions";

export const makeAnswerReadable = (
  answer: string,
  questionType: string,
  simplified: number
) => {
  let text = getButtons(questionType, [], simplified).filter(
    // eslint-disable-next-line
    (button) => button.value == answer
  )[0]?.text;
  return text;
};

export const getDescription = (questionType: string) => {
  switch (questionType) {
    case "light-non-sexual-touch":
      return "This category is about touching that is not specifically sexual in nature. It includes things like holding hands, hugging, and cuddling.";
    case "positions":
      return "This category is about sexual positions.";
    case "preparation":
      return "This category is about various things you can do to prepare for sex.";
    case "oral":
      return "This category is about using your mouth for sexual pleasure.";
    case "menstruation":
      return "This category is about menstruation.";
    case "media":
      return "This category is about using media, like music, during sex.";
    case "foreplay":
      return "This category is about sexual activities that are often considered foreplay.";
    case "bdsm":
      return "This category is about some of the edgier activities couples engage in.";
    case "aural":
      return "This category is about using sounds for sexual pleasure, such as moaning and dirty talk.";
    case "anal":
      return "This category is about using your anus for sexual pleasure.";
    case "toys":
      return "This category is about using sex toys.";
    case "initiation":
      return "This category is about different ways to initiate sex.";
    case "rear":
      return 'This category is about words relating to the "rear" and how those words make you feel.';
    case "ejaculate":
      return "This category is about words relating to ejaculate and how those words make you feel.";
    case "testicles":
      return "This category is about words relating to testicles and how those words make you feel.";
    case "female_genitals":
      return "This category is about words relating to female genitals and how those words make you feel.";
    case "breasts":
      return "This category is about words relating to breasts and how those words make you feel.";
    case "male_genitals":
      return "This category is about words relating to male genitals and how those words make you feel.";
    case "names":
      return "This category is about words relating to how you address your spouse, or they address you and how those words make you feel.";
    case "attributes":
      return "This category is about words relating to attributes and how those words make you feel.";
    case "activities":
      return "This category is about words relating to activities and how those words make you feel.";
    case "sounds":
      return "This category is about sounds and how those sounds make you feel.";
    case "feelings":
      return "This category is about which feelings and your experiencing of them during sex.";
    case "places":
      return "This category is about places where you could have sex.";
  }
};

export const getButtons = (
  question_type: string,
  buttons: AnswerButton[],
  simplified: number
) => {
  if (simplified) {
    buttons = [
      {
        text: "Yes",
        value: "yes",
        backgroundcolor: "#ef6a6a",
        color: "black",
        type: "positive",
        cols: "col-span-4",
      },

      {
        text: "Maybe",
        value: "maybe",
        backgroundcolor: "#dd9a9a",
        color: "black",
        type: "neutral",
        cols: "col-span-4",
      },
      {
        text: "No",
        value: "no",
        backgroundcolor: "#b21f1f",
        color: "white",
        type: "negative",
        cols: "col-span-4",
      },
    ];
  } else {
    switch (question_type) {
      case "light-non-sexual-touch":
      case "positions":
      case "preparation":
      case "oral":
      case "menstruation":
      case "media":
      case "foreplay":
      case "bdsm":
      case "aural":
      case "anal":
      case "toys":
      case "misc":
      case "places":
      case "initiation":
        buttons = [
          {
            text: "I like this",
            value: "like",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-4",
          },
          {
            text: "I want more of this",
            value: "more",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-4",
          },
          {
            text: "I want to do/try this",
            value: "want",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-4",
          },
          {
            text: "I am willing to do this",
            value: "willing",
            backgroundcolor: "#dd9a9a",
            color: "black",
            type: "neutral",
            cols: "col-span-4",
          },
          {
            text: "Neutral / conflicted",
            value: "neutral",
            backgroundcolor: "#dd9a9a",
            color: "black",
            type: "neutral",
            cols: "col-span-4",
          },
          {
            text: "I might be willing to try this",
            value: "try",
            backgroundcolor: "#dd9a9a",
            color: "black",
            type: "neutral",
            cols: "col-span-4",
          },
          {
            text: "I'm not ready to try this yet",
            value: "not-ready",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
          {
            text: "I can't imagine wanting to do this",
            value: "do-not-want",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
          {
            text: "I want to stop doing this",
            value: "stop",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
        ];
        break;
      case "rear":
      case "ejaculate":
      case "testicles":
      case "female_genitals":
      case "breasts":
      case "male_genitals":
      case "names":
      case "attributes":
      case "activities":
      case "sounds":
        buttons = [
          {
            text: "Really turns me on",
            value: "turn-on",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-6",
          },
          {
            text: "I find it arousing",
            value: "arousing",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-6",
          },
          {
            text: "Neutral",
            value: "neutral",
            backgroundcolor: "#dd9a9a",
            color: "black",
            type: "neutral",
            cols: "col-span-12",
          },
          {
            text: "Do not care for it",
            value: "do-not-care",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
          {
            text: "Turns me off",
            value: "turn-off",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
          {
            text: "Shuts me down",
            value: "shut-down",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-4",
          },
        ];

        break;
      case "feelings":
        buttons = [
          {
            text: "I feel this and like it",
            value: "like",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-6",
          },
          {
            text: "I want to feel this but do not",
            value: "want",
            backgroundcolor: "#ef6a6a",
            color: "black",
            type: "positive",
            cols: "col-span-6",
          },
          {
            text: "Neutral",
            value: "neutral",
            backgroundcolor: "#dd9a9a",
            color: "black",
            type: "neutral",
            cols: "col-span-12",
          },
          {
            text: "I feel this and do not want to",
            value: "do-not-want",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-6",
          },
          {
            text: "I don't and don't want to feel this",
            value: "stop",
            backgroundcolor: "#b21f1f",
            color: "white",
            type: "negative",
            cols: "col-span-6",
          },
        ];
        break;
    }
  }
  return buttons;
};

export type AnswerButton = {
  text: string;
  value: string;
  color: string;
  backgroundcolor: string;
  type: "positive" | "neutral" | "negative";
  cols: string;
};

export const otherSpouse = (spouse: string) => {
  if (spouse.toLowerCase() === "husband") {
    return "wife";
  } else if (spouse.toLowerCase() === "wife") {
    return "husband";
  } else {
    return "error";
  }
};

export const getQuestionOutput = (
  question: Question | undefined,
  spouse: string,
  refetch: Function,
  unansweredQuestions: UseQueryResult<Question[], unknown>,
  logout: Function,
  questionsRemaining?: string
) => {
  if (question !== undefined) {
    return (
      <React.Fragment>
        <ShowQuestion question={question} spouse={spouse} refetch={refetch} />
        <div className="mt-2">
          {questionsRemaining ? <div>{questionsRemaining}</div> : ""}
          <div>
            {unansweredQuestions.data?.length + " questions remaining total"}
          </div>
        </div>
      </React.Fragment>
    );
  } else if (unansweredQuestions.isSuccess) {
    return <NoMoreQuestions spouse={spouse} />;
  } else if (unansweredQuestions.isLoading) {
    return <p>Loading...</p>;
  } else if (unansweredQuestions.isError) {
    if ((unansweredQuestions.error as any).response.status === 401) {
      return (
        <React.Fragment>
          <p>Login expired.</p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Something went wrong - was not successful in loading questions</p>
        </React.Fragment>
      );
    }
  }
};
