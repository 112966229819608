export default function Card(props: {
  children: React.ReactNode;
  className?: string;
  color?: string;
}) {
  let color = props.color !== undefined ? props.color : "bg-white";

  return (
    <div
      className={
        " " +
        color +
        " px-4 py-5 sm:px-6 " +
        (props.className ? " " + props.className : "")
      }
    >
      {props.children}
    </div>
  );
}

export function CardHeading(props: { children: React.ReactNode }) {
  return (
    <h3 className={"text-base font-semibold leading-6"}>{props.children}</h3>
  );
}

export function CardBody(props: { children: React.ReactNode }) {
  return <div className={"mt-1 text-sm"}>{props.children}</div>;
}
