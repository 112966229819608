import { UserContext } from "App";
import Header from "components/header";
import { Field, Form, Formik } from "formik";
import { useSessions } from "hooks/useOurSexplorationListSessions";
import React, { useCallback, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Session } from "types/Sessions";
import { useAuth } from "hooks/useAuth";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import Card, { CardBody } from "components/card";
import Button from "components/button";
import Badge from "components/badge";

export default function Sessions() {
  const user = useContext(UserContext);
  const { updateOurSexplorationListCurrentSessionID } = useAuth();
  const { refetch } = useOurSexplorationListQuestions();
  const {
    sessions,
    deleteSession,
    archiveSession,
    unarchiveSession,
    createSession,
    updateSessionNotes,
    getBadgeColor,
    makeStatusReadable,
  } = useSessions();
  const [status, setStatus] = React.useState("active");
  const [editNoteID, setEditNoteID] = React.useState(-1);
  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Sessions",
      link: "/OurSexplorationList/Sessions",
      active: true,
    },
  ];

  const handleDeleteSession = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const response = window.confirm(
        "Are you sure you want to delete this session?"
      );
      if (response) {
        if (event.currentTarget.dataset.id === undefined) {
          toast("Error deleting session!", {
            type: "error",
          });
        } else {
          const id: number = Number.parseInt(event.currentTarget.dataset.id);
          deleteSession(id);
          toast("Session deleted!", {
            type: "success",
          });
        }
      }
    },
    [sessions]
  );

  const handleArchiveSession = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.currentTarget.dataset.id === undefined) {
        toast("Error archiving session!", {
          type: "error",
        });
      } else {
        const id: number = Number.parseInt(event.currentTarget.dataset.id);
        archiveSession(id);
        toast("Session archived!", {
          type: "success",
        });
      }
    },
    [sessions]
  );

  const handleUnArchiveSession = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.currentTarget.dataset.id === undefined) {
        toast("Error unarchiving session!", {
          type: "error",
        });
      } else {
        const id: number = Number.parseInt(event.currentTarget.dataset.id);
        unarchiveSession(id);
        toast("Session unarchived!", {
          type: "success",
        });
      }
    },
    [sessions]
  );

  const handleCreateSession = useCallback(
    (event: any) => {
      createSession("Session created " + new Date().toLocaleDateString());
      toast("Session created!", {
        type: "success",
      });
    },
    [sessions]
  );

  const switchView = useCallback(() => {
    if (status === "active") {
      setStatus("archived");
    } else {
      setStatus("active");
    }
  }, [status]);

  const handleNoteChange = useCallback(
    (values: any) => {
      const id: number = values.id;
      const notes: string = values.notes;
      updateSessionNotes(id, notes);
      toast("Notes updated!", {
        type: "success",
      });
      setEditNoteID(-1);
    },
    [sessions]
  );

  const handleEditNotes = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log(event.target);
      if (event.currentTarget.dataset.id === undefined) {
        toast("Error editing notes!", {
          type: "error",
        });
      } else {
        const id: number = Number.parseInt(event.currentTarget.dataset.id);
        setEditNoteID(id);
      }
    },
    [editNoteID]
  );

  const handleCancelEditNotes = useCallback(
    (event: any) => {
      setEditNoteID(-1);
    },
    [editNoteID]
  );

  const handleSelectSession = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.currentTarget.dataset.id === undefined) {
        toast("Error selecting session!", {
          type: "error",
        });
      } else {
        const id: number = Number.parseInt(event.currentTarget.dataset.id);

        await updateOurSexplorationListCurrentSessionID(id);
        refetch();

        window.location.href = "/OurSexplorationList/Sessions";
      }
    },
    [sessions]
  );

  if (sessions.isSuccess) {
    sessions.data = sessions.data.filter((session: Session) => {
      return session.status === status;
    });
  }

  document.title = "Sessions | Our Sexploration List | Uncovering Intimacy";

  return (
    <React.Fragment>
      <Header title="Manage Sessions" breadcrumbs={breadcrumbs} h1="Sessions">
        {status === "active" ? (
          <Button onClick={handleCreateSession}>Start new session</Button>
        ) : (
          ""
        )}{" "}
        <Button onClick={switchView}>
          {status === "active" ? "Show archived" : "Show active"}
        </Button>
        {sessions.isLoading && <p>Loading...</p>}
        {sessions.isSuccess
          ? sessions.data.map((session: Session) => (
              <Card key={session.id}>
                <CardBody>
                  {status === "active" ? (
                    user.oursexplorationlist_current_session_id !=
                    session.id ? (
                      <React.Fragment>
                        <Button
                          className="float-end"
                          data-id={session.id}
                          onClick={handleSelectSession}
                        >
                          Select
                        </Button>
                        <Button
                          className="float-end"
                          data-id={session.id}
                          onClick={handleArchiveSession}
                        >
                          Archive
                        </Button>
                      </React.Fragment>
                    ) : null
                  ) : (
                    <div className="float-end btn-block">
                      <Button
                        className="w-100"
                        data-id={session.id}
                        onClick={handleUnArchiveSession}
                      >
                        Unarchive
                      </Button>
                      <Button
                        className="w-100"
                        data-id={session.id}
                        color="danger"
                        onClick={handleDeleteSession}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                  <h3>
                    {editNoteID == session.id ? (
                      <Formik
                        initialValues={{
                          id: session.id,
                          notes: session.notes,
                        }}
                        onSubmit={handleNoteChange}
                        onReset={handleCancelEditNotes}
                      >
                        {(props) => (
                          <Form>
                            <Field type="hidden" name="id" />
                            <Field name="notes" />
                            <Button data-values={props.values} type="submit">
                              Save
                            </Button>
                            <Button type="reset">Cancel</Button>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <React.Fragment>
                        {session.notes +
                          (user.oursexplorationlist_current_session_id ==
                          session.id
                            ? " (Current)"
                            : "") +
                          " "}
                        <Button
                          onClick={handleEditNotes}
                          data-id={session.id}
                          className="text-xs"
                        >
                          Edit
                        </Button>
                      </React.Fragment>
                    )}
                  </h3>
                  Husband:
                  <Badge
                    className="m-2"
                    color={getBadgeColor(session.husband_status)}
                  >
                    {makeStatusReadable(session.husband_status)}
                  </Badge>
                  Wife:
                  <Badge
                    className="m-2"
                    color={getBadgeColor(session.wife_status)}
                  >
                    {makeStatusReadable(session.wife_status)}
                  </Badge>
                </CardBody>
              </Card>
            ))
          : ""}
      </Header>
    </React.Fragment>
  );
}
