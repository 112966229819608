export type Question = {
  id: number;
  question: string;
  tags: string;
  created_at: string;
  active: boolean;
  paired_question: number;
  question_type: string;
  more_info_label: string;
  more_info_url: string;
  gender_specific: string;
};

export type QuestionType = {
  value: string;
  label: string;
  subTypes?: QuestionType[];
};

export const question_types: QuestionType[] = [
  {
    value: "activities",
    label: "Activities",
    subTypes: [
      {
        value: "light-non-sexual-touch",
        label: "Non, or light, sexual touch",
      },
      { value: "positions", label: "Positions" },
      { value: "preparation", label: "Preparation & Pre-sex" },
      { value: "oral", label: "Oral sex & ejaculation" },
      { value: "menstruation", label: "Menstruation" },
      { value: "media", label: "Media & sex" },
      { value: "foreplay", label: "Foreplay" },
      {
        value: "bdsm",
        label: "Bondage, dominance & submission, impact & temperature play",
      },
      { value: "aural", label: "Aural sex" },
      { value: "anal", label: "Anal play & sex" },
      { value: "toys", label: "Toys" },
      { value: "misc", label: "Misc" },
    ],
  },
  {
    value: "words",
    label: "Words",
    subTypes: [
      {
        value: "bodyparts",
        label: "Body parts",
        subTypes: [
          { value: "rear", label: "Rear" },
          { value: "ejaculate", label: "Ejaculate" },
          { value: "testicles", label: "Testicles" },
          { value: "female_genitals", label: "Female genitals" },
          { value: "breasts", label: "Breasts" },
          { value: "male_genitals", label: "Male genitals" },
        ],
      },
      { value: "names", label: "Names" },
      { value: "attributes", label: "Attributes, descriptors, misc" },
      { value: "activities", label: "Activities" },
    ],
  },
  { value: "sounds", label: "Sounds" },
  { value: "feelings", label: "Feelings" },
  //TODO Add places?
];

export const makeQuestionTypeReadable = (type: string) => {
  const answer = checkQuestionType(type, question_types);
  if (answer !== null) {
    return answer;
  } else {
    return "";
  }
};

const checkQuestionType = (
  type: string,
  question_types: QuestionType[]
): string => {
  let answer: string = "";
  question_types.every((question_type) => {
    if (question_type.value === type) {
      answer = question_type.label;
      return false;
    } else if (question_type.subTypes !== undefined) {
      answer = checkQuestionType(type, question_type.subTypes);
      if (answer !== "") return false;
    }
    return true;
  });
  return answer;
};

export type Answer = {
  id: number;
  question_id: number;
  user_id: number;
  answer: string;
  created_at: string;
  updated_at: string;
  session_id: number;
  spouse: string;
  notes: string;
};

export type Response = {
  questionId: number;
  paired_questionId: number | undefined;
  question: string;
  paired_question: string | undefined;
  husband: string;
  wife: string;
  husband_notes: string;
  wife_notes: string;
};

// interface for responses
export interface Responses {
  [questionType: string]: Response[];
}

export interface Matches {
  [matchType: string]: Responses;
}
