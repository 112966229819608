import React from "react";

import { useAuth } from "../hooks/useAuth";

export default function Logout() {
  const { logout } = useAuth();

  logout();

  return <React.Fragment></React.Fragment>;
}
