import Header from "components/header";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Question } from "types/Questions";
import ShowQuestion from "./_ShowQuestion";

export default function SpecificQuestion(props: any) {
  const params = useParams();
  const spouse: string = params.spouse || "";
  const returnTo: string = params.returnTo || "Matches";
  const questionId: number = parseInt(params.questionId || "");
  const [question, setQuestion] = React.useState<Question | undefined>(
    undefined
  );
  const { questions } = useOurSexplorationListQuestions();

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: false,
    },
    {
      name: "Specific Question",
      link:
        "/OurSexplorationList/AnswerQuestions/Specific/" +
        spouse +
        "/" +
        questionId,
      active: true,
    },
  ];

  useEffect(() => {
    if (questions.isSuccess) {
      setQuestion(questions.data.find((q) => q.id == questionId));
    }
  }, [questions]);

  let redirectUrl = "";
  switch (returnTo) {
    case "Matches":
      redirectUrl = "/OurSexplorationList/FindMatches";
      break;
    case "Answered":
      redirectUrl =
        "/OurSexplorationList/AnswerQuestions/AlreadyAnswered/" + spouse;
      break;
    case "Answers":
      redirectUrl = "/OurSexplorationList/ViewAnswers/" + spouse;
      break;
  }

  return (
    <React.Fragment>
      <Header
        title="Questions in order | Our Sexploration List | Uncovering Intimacy"
        h1="Questions in order"
        breadcrumbs={breadcrumbs}
      >
        {question !== undefined ? (
          <ShowQuestion
            question={question}
            spouse={spouse}
            refetch={questions.refetch}
            redirectUrl={redirectUrl}
          />
        ) : (
          <p>No questions found.</p>
        )}
        </Header>
    </React.Fragment>
  );
}
