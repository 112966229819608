export type Question = {
  question: string;
  type: string;
  answers: string[];
};

export const quizQuestions = [
  {
    question:
      "How often do you feel sexually aroused without being prompted or stimulated by someone or something else?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "spontaneous",
  },
  {
    question:
      "How much does a romantic or sexual situation affect your arousal?",
    answers: [
      "Not at all",
      "A small effect",
      "A large effect",
      "Context is everything",
    ],
    type: "responsive",
  },
  {
    question:
      "How often do you randomly think about sexual activity or fantasies?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "spontaneous",
  },
  {
    question:
      "How much of an impact do your emotions have on your desire for sexual activity?",
    answers: [
      "No impact",
      "Very little impact",
      "Some impact",
      "A lot of impact",
    ],
    type: "responsive",
  },
  {
    question:
      "How often do you feel increased desire for sexual activity after being physically or emotionally intimate with your partner?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "responsive",
  },
  {
    question:
      "How often do you feel increased desire for sexual activity after engaging in activities that you find pleasurable or relaxing (e.g. reading a book, going for a walk)?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "responsive",
  },
  {
    question:
      "If/when you have sex because you know your spouse wants or needs it, but you don't feel any desire at the outset, how often are you able to get in the mood and enjoy it by the end?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "responsive",
  },
  {
    question:
      "How much do external factors (e.g. your spouse's appearance, their actions or words) influence your desire (positively or negatively) for sexual activity?",
    answers: [
      "No influence",
      "Very little influence",
      "Some influence",
      "A lot of influence",
    ],
    type: "responsive",
  },
  {
    question:
      "How much foreplay do you feel you need to get in the mood for sex?",
    answers: ["None needed", "A bit at least", "Quite a bit", "A lot"],
    type: "responsive",
  },
  {
    question:
      "How often do you initiate sex because you want it, not because you feel your spouse wants it?",
    answers: ["Never", "Rarely", "Occasionally", "Very often"],
    type: "spontaneous",
  },
];
