import Button from "components/button";
import Header from "components/header";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { Label } from "reactstrap";
import { question_types, makeQuestionTypeReadable } from "types/Questions";

export default function ManageQuestions(props: any) {
  const { questions, createQuestion, deleteQuestion, pairQuestion } =
    useOurSexplorationListQuestions();
  const [tags, setTags] = React.useState<string[]>([]);

  useEffect(() => {
    if (questions.data) {
      const tags: string[] = [];
      questions.data.forEach((question) => {
        if (question.tags) {
          question.tags.split(",").forEach((tag) => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        }
      });
      setTags(tags);
    }
  }, [questions.data]);

  type SubmitQuestionType = {
    question: string;
    tags: string[];
    newTag: string;
    paired_question: number;
    active: boolean;
    question_type: string;
    more_info_label: string;
    more_info_url: string;
    gender_specific: string;
  };

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Manage Questions",
      link: "/OurSexplorationList/ManageQuestions",
      active: true,
    },
  ];

  const initialValues = {
    question: "",
    tags: [] as string[],
    newTag: "",
    paired_question: 0,
    active: true,
    question_type: "places",
    more_info_label: "",
    more_info_url: "",
    gender_specific: "both",
  };

  const handleFormSubmit = useCallback(
    async (values: any, formikHelper: FormikHelpers<SubmitQuestionType>) => {
      const questionTags: string[] = values.tags || [];
      if (values.newTag !== undefined && values.newTag !== "") {
        questionTags.push(values.newTag);
      }
      const paired_question = parseInt(values.paired_question);

      const response = await createQuestion(
        values.question,
        questionTags.join(","),
        values.active,
        paired_question === 0 ? null : paired_question,
        values.question_type,
        values.more_info_label,
        values.more_info_url,
        values.gender_specific
      );
      toast(response.message, {
        type: response.success ? "success" : "error",
      });
      if (response.success && paired_question > 0) {
        const response2 = pairQuestion(paired_question, response.id);
        toast((await response2).message, {
          type: (await response2).success ? "success" : "error",
        });
      }

      formikHelper.resetForm({ values: initialValues });
      setTags([]);
    },
    [questions.data]
  );

  const getTagCheckboxes = () => {
    if (tags.length > 0) {
      return tags.map((tag, index) => (
        <div key={tag}>
          <Field value={tag} name="tags" type="checkbox" />
          <span>{tag}</span>
        </div>
      ));
    } else {
      return "";
    }
  };

  const handleDeleteQuestion = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const id = event.currentTarget.getAttribute("data-id");
      const response = window.confirm(
        "Are you sure you want to delete this session?"
      );
      if (response) {
        if (id) {
          deleteQuestion(parseInt(id));
        }
      }
    },
    [questions.data]
  );

  return (
    <React.Fragment>
      <Header
        breadcrumbs={breadcrumbs}
        title="Manage Questions | Our Sexploration List | Uncovering Intimacy"
        h1="Manage Questions"
      >
        {questions.isLoading ? (
          <div>Loading...</div>
        ) : (
          <React.Fragment>
            <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
              {({ values, setFieldValue }) => (
                <Form>
                  <table>
                    <tr>
                      <td>
                        <Label for="question">Question</Label>
                      </td>
                      <td>
                        <Field type="text" name="question" className="w-100" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="tags">Tags</Label>
                      </td>
                      <td>
                        {getTagCheckboxes()}
                        <Field type="text" name="newTag" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="paired_question">Paired question</Label>
                      </td>
                      <td>
                        <Field as="select" name="paired_question">
                          <option value="">None</option>
                          {questions.data
                            ?.sort((a, b) => {
                              return b.id - a.id;
                            })
                            .map((question) => (
                              <option key={question.id} value={question.id}>
                                {question.question}
                              </option>
                            ))}
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="question_type">Question Type</Label>
                      </td>
                      <td>
                        <Field as="select" name="question_type">
                          {question_types.map((question_type) => (
                            <React.Fragment key={question_type.value}>
                              <option value={question_type.value}>
                                {question_type.label}
                              </option>
                              {question_type.subTypes?.map((subType) => (
                                <React.Fragment key={subType.value}>
                                  <option value={subType.value}>
                                    {"-- " + subType.label}
                                  </option>
                                  {subType.subTypes?.map((subSubType) => (
                                    <option
                                      key={subSubType.value}
                                      value={subSubType.value}
                                    >
                                      {"---- " + subSubType.label}
                                    </option>
                                  ))}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))}
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="more_info_label">More info label</Label>
                      </td>
                      <td>
                        <Field type="text" name="more_info_label" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="more_info_url">More info URL</Label>
                      </td>
                      <td>
                        <Field type="text" name="more_info_url" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="active">Active</Label>
                      </td>
                      <td>
                        <Field type="checkbox" name="active" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label for="gender_specific">Gender specific</Label>
                      </td>
                      <td>
                        <Field as="select" name="gender_specific">
                          <option value="both">Both</option>
                          <option value="husband">Husband</option>
                          <option value="wife">Wife</option>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button type="submit" className="w-100">
                          Add Question
                        </Button>
                      </td>
                    </tr>
                  </table>
                </Form>
              )}
            </Formik>

            {questions.data === undefined ? (
              <div>No questions found</div>
            ) : (
              <React.Fragment>
                <tr>
                  <td>ID</td>
                  <td>Question</td>
                  <td>Tags</td>
                  <td>Active</td>
                  <td>Pair</td>
                  <td>Question type</td>
                  <td>Gender</td>
                  <td></td>
                </tr>
                {questions.data.map((question) => (
                  <tr key={question.id}>
                    <td>{question.id}</td>
                    <td>{question.question}</td>
                    <td>{question.tags}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={question.active}
                        readOnly={true}
                      />
                    </td>
                    <td>{question.paired_question}</td>
                    <td>{makeQuestionTypeReadable(question.question_type)}</td>
                    <td>{question.gender_specific}</td>
                    <td>
                      <Button
                        onClick={handleDeleteQuestion}
                        data-id={question.id}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Header>
    </React.Fragment>
  );
}
