import Header from "components/header";
import { useOurSexplorationListAnswers } from "hooks/useOurSexplorationListAnswers";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Answer } from "types/OurSexplorationList";
import { makeAnswerReadable, otherSpouse } from "./_Functions";
import { UserContext } from "App";
import Card, { CardBody, CardHeading } from "components/card";
import LinkButton from "components/linkbutton";
import { getFullPath, question_types } from "types/Questions";
import { getDescription } from "./_Functions";
import MoreInfo from "components/moreInfo";

export default function ViewAnswers() {
  const user = useContext(UserContext);
  const params = useParams();
  const spouse: string = params.spouse || "";
  const { answers } = useOurSexplorationListAnswers();
  const { questions } = useOurSexplorationListQuestions();

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "View Answers",
      link: "/OurSexplorationList/ViewAnswers",
      active: true,
    },
  ];

  interface SortedAnswers {
    [key: string]: {
      [question_type: string]: {
        question: string;
        answer: string;
        notes: string;
        answer_id: number;
        question_id: number;
      }[];
    };
  }

  let sortedAnswers: SortedAnswers = {};

  if (answers.isSuccess && questions.isSuccess) {
    let filteredAnswers = answers.data.filter(
      (a) => a.spouse === spouse.toLowerCase()
    );
    filteredAnswers.forEach((answer: Answer) => {
      const question = questions.data.find((q) => q.id == answer.question_id);
      if (question) {
        const answerString: string =
          makeAnswerReadable(
            answer.answer,
            question.question_type,
            user.oursexplorationlist_simplified_version
          ) || "";

        if (!sortedAnswers[answerString]) sortedAnswers[answerString] = {};
        if (!sortedAnswers[answerString][question.question_type])
          sortedAnswers[answerString][question.question_type] = [];
        sortedAnswers[answerString][question.question_type].push({
          question: question.question
            .replaceAll("{spouse}", otherSpouse(spouse))
            .replaceAll(
              "{their}",
              spouse.toLowerCase() === "wife" ? "his" : "her"
            ),
          answer: answer.answer,
          notes: answer.notes,
          answer_id: answer.id,
          question_id: answer.question_id,
        });
      }
    });
  }

  if (answers.isLoading || questions.isLoading) return <div>Loading...</div>;

  return (
    <React.Fragment>
      <Header
        h1={"View Answers - " + spouse}
        title="View Answers | Our Sexploration List | Uncovering Intimacy"
        breadcrumbs={breadcrumbs}
      >
        {Object.keys(sortedAnswers).map((key) => {
          return (
            <Card key={key} className="mb-4">
              <CardHeading>{key}</CardHeading>
              <CardBody>
                {Object.keys(sortedAnswers[key]).map((question_type) => {
                  return (
                    <React.Fragment key={question_type}>
                      <div className="mb-2">
                        <h2 className="text-lg font-semibold text-gray-900">
                          {getFullPath(question_type, question_types)}
                          <MoreInfo description={getDescription(question_type)}>
                            More info
                          </MoreInfo>
                        </h2>
                      </div>
                      <ul className="divide-y divide-gray-200">
                        {sortedAnswers[key][question_type].map((answer) => (
                          <li
                            key={answer.answer_id}
                            className="flex justify-between items-center py-2"
                          >
                            <div className="flex-1">
                              <p className="text-sm font-medium text-gray-900">
                                {answer.question}
                              </p>
                              {answer.notes && (
                                <p className="text-sm text-gray-500 mt-1">
                                  Notes: {answer.notes}
                                </p>
                              )}
                            </div>
                            <LinkButton
                              className="ml-4 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                              to={`/OurSexplorationList/AnswerQuestions/Specific/${spouse}/${answer.question_id}/Answers`}
                              color={
                                spouse.toLowerCase() === "wife"
                                  ? "pink"
                                  : "blue"
                              }
                            >
                              <i className="fas fa-edit"></i>
                              Change
                            </LinkButton>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </CardBody>
            </Card>
          );
        })}
      </Header>
    </React.Fragment>
  );
}
