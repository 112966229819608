import Header from "components/header";
import { useOurSexplorationListAnswers } from "hooks/useOurSexplorationListAnswers";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { getButtons, makeAnswerReadable } from "./_Functions";
import { UserContext } from "App";
import Card, { CardBody, CardHeading } from "components/card";
import LinkButton from "components/linkbutton";

export default function QuestionsAlreadyAnswered(props: any) {
  const user = useContext(UserContext);
  const params = useParams();
  const spouse: string = params.spouse || "";

  const { answeredQuestions } = useOurSexplorationListQuestions(
    spouse.toLowerCase()
  );
  const { answers } = useOurSexplorationListAnswers();

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: false,
    },
    {
      name: "Questions already answered",
      link: "/OurSexplorationList/AnswerQuestion/AlreadyAnswered/" + spouse,
      active: true,
    },
  ];

  const getOutput = () => {
    if (answeredQuestions.isLoading || answers.isLoading) {
      return <p>Loading...</p>;
    } else if (answeredQuestions.isError || answers.isError) {
      return (
        <p>Something went wrong - was not successful in loading questions</p>
      );
    } else if (answeredQuestions.isSuccess && answers.isSuccess) {
      if (answeredQuestions.data?.length == 0) {
        return (
          <React.Fragment>
            <p>It doesn't look like you've answered any questions yet.</p>
            <Link
              to={"/OurSexplorationList/AnswerQuestions/" + spouse}
              className={"btn btn-" + spouse.toLowerCase() + " w-100"}
            >
              Answer Questions
            </Link>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {answeredQuestions.data.map((question) => {
              const answer = answers.data.find(
                (answer) => answer.question_id == question.id
              );
              const buttons = getButtons(
                question.question_type,
                [],
                user.oursexplorationlist_simplified_version
              );
              const button = buttons.find(
                (button) => button.value == answer?.answer
              );

              return (
                <Card key={question.id} className="mt-2">
                  <CardHeading>
                    {question.question.replaceAll(
                      "{spouse}",
                      spouse.toLowerCase() === "husband" ? "wife" : "husband"
                    )}
                  </CardHeading>
                  <CardBody>
                    {makeAnswerReadable(
                      answer?.answer || "",
                      question.question_type,
                      user.oursexplorationlist_simplified_version
                    )}
                    <LinkButton
                      className="btn btn-sm btn-primary ms-2"
                      to={
                        "/OurSexplorationList/AnswerQuestions/Specific/" +
                        spouse.toLowerCase() +
                        "/" +
                        question.id +
                        "/Answered"
                      }
                    >
                      <i className="fas fa-edit"></i>
                      Edit
                    </LinkButton>
                  </CardBody>
                </Card>
              );
            })}
          </React.Fragment>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Header
        title="Questions in order | Our Sexploration List | Uncovering Intimacy"
        h1="Questions in order"
        breadcrumbs={breadcrumbs}
      >
        {getOutput()}
      </Header>
    </React.Fragment>
  );
}
