import { useAuth } from "hooks/useAuth";
import React from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "assets/images/icon.png";

export default function Layout({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();

  const navigation = [
    { name: "Home", href: "#", current: true },
    {
      name: "Blog",
      href: "https://www.uncoveringintimacy.com",
      current: false,
    },
    {
      name: "Store",
      href: "https://www.uncoveringintimacy.com/shop",
      current: false,
    },
  ];

  const userNavigation = [
    { name: "Settings", href: "/Settings" },
    { name: "Log out", href: "/Logout" },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src={logo}
                      alt="Uncovering Intimacy"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {user ? (
                      <React.Fragment>
                        <button
                          type="button"
                          className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <div>User</div>
                              {/* <img
                                className="h-8 w-8 rounded-full"
                                src={user.imageUrl}
                                alt=""
                              /> */}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={React.Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  {user ? (
                    <React.Fragment>
                      <div className="flex-shrink-0">
                        <div>User</div>
                        {/* <img
                          className="h-10 w-10 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        /> */}
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium text-gray-400">
                          {user.email}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        Sign in
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        to manage your account
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {user ? (
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {children}
    </div>
  );

  // return (
  //   <Navbar bg="light" expand="lg" className="mb-4">
  //     <Container>
  //       <Row>
  //         <Col xs={3}>
  //           <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //         </Col>
  //         <Col xs={9}>
  //           <Navbar.Brand href="/">
  //             <img alt="Uncovering Intimacy Logo" src={logo} className="logo" />
  //           </Navbar.Brand>
  //         </Col>
  //       </Row>

  //       <Navbar.Collapse id="basic-navbar-nav">
  //         <Nav className="me-auto">
  //           <Nav.Link href="/">Home</Nav.Link>
  //           <Nav.Link href="https://www.uncoveringintimacy.com/">Blog</Nav.Link>

  //           <NavDropdown title="Account" id="basic-nav-dropdown">
  //             {user.isLoggedIn ? (
  //               <React.Fragment>
  //                 <NavDropdown.Item href="/Settings">Settings</NavDropdown.Item>
  //                 <NavDropdown.Item onClick={handleLogout}>
  //                   Logout
  //                 </NavDropdown.Item>
  //               </React.Fragment>
  //             ) : (
  //               <React.Fragment>
  //                 <NavDropdown.Item
  //                   href="https://www.uncoveringintimacy.com/register"
  //                   target="_blank"
  //                 >
  //                   Register
  //                 </NavDropdown.Item>
  //                 <NavDropdown.Item
  //                   href="https://www.uncoveringintimacy.com/lostpassword"
  //                   target="_blank"
  //                 >
  //                   Lost password
  //                 </NavDropdown.Item>
  //               </React.Fragment>
  //             )}

  //             {/* <NavDropdown.Item href="#action/3.2">
  //               Another action
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
  //             <NavDropdown.Divider />
  //             <NavDropdown.Item href="#action/3.4">
  //               Separated link
  //             </NavDropdown.Item> */}
  //           </NavDropdown>
  //         </Nav>
  //       </Navbar.Collapse>
  //     </Container>
  //   </Navbar>
  // );
}
