import React from "react";
import { getColors } from "./_globalFunctions";

export default function Badge(props: {
  children: React.ReactNode;
  color?: string;
  className?: string | "";
}) {
  let color = props.color || "gray";
  let { lightColor, ringColor, darkTextColor } = getColors(color);

  return (
    <React.Fragment>
      <span
        className={
          "inline-flex items-center rounded-md " +
          lightColor +
          " px-2 py-1 text-xs font-medium " +
          darkTextColor +
          " ring-1 ring-inset " +
          ringColor +
          " " +
          props.className
        }
      >
        {props.children}
      </span>
    </React.Fragment>
  );
}
