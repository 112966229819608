import React from "react";
import { breadcrumb } from "./breadcrumbs";
import { HomeIcon } from "@heroicons/react/24/outline";

export default function Header({
  title,
  h1,
  breadcrumbs,
  children,
}: {
  title: string;
  h1: string;
  breadcrumbs: breadcrumb[];
  children: React.ReactNode;
}) {
  document.title = title;

  return (
    <React.Fragment>
      <header className="bg-white shadow-sm">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <h1 className="text-lg font-semibold leading-6 text-gray-900 mb-3">
            {h1}
          </h1>
          {breadcrumbs.length > 0 && (
            <nav className="flex" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a href="/" className="text-gray-400 hover:text-gray-500">
                      <HomeIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.name} className="flex">
                    <div className="flex items-center">
                      <svg
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <a
                        href={breadcrumb.link}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={breadcrumb.active ? "page" : undefined}
                      >
                        {breadcrumb.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          )}
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </React.Fragment>
  );
}
