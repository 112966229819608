import { CSSProperties, MouseEventHandler } from "react";
import { getColors } from "./_globalFunctions";

export default function Button(props: {
  children: React.ReactNode;
  className?: string;
  color?: string | "gray";
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  name?: string;
  type?: "button" | "submit" | "reset" | undefined;
  style?: CSSProperties | undefined;
  value?: string | undefined;
  "data-id"?: number | undefined;
}) {
  let { bgColor, hoverColor } = getColors(props.color);

  return (
    <button
      type={props.type}
      name={props.name}
      className={
        "rounded " +
        bgColor +
        " px-2 py-1 font-semibold text-white shadow-sm hover:" +
        hoverColor +
        " focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:" +
        bgColor +
        " " +
        props.className
      }
      data-id={props["data-id"]}
      style={props.style}
      value={props.value}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
