import {
  faCheckCircle,
  faListNumeric,
  faPersonCircleQuestion,
  faRandom,
  faListSquares,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card, { CardBody, CardHeading } from "components/card";
import Header from "components/header";
import LinkButton from "components/linkbutton";
import React from "react";
import { useParams } from "react-router";

document.title =
  "Answer Questions | Our Sexploration List | Uncovering Intimacy";

export default function AnswerQuestions(props: any) {
  const params = useParams();

  const spouse = params.spouse || "";

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: true,
    },
  ];

  const questionTypes = [
    {
      type: "random",
      name: "Random",
      icon: faRandom,
      link: "/OurSexplorationList/AnswerQuestions/Random/" + spouse,
    },
    {
      type: "inOrder",
      name: "In order",
      icon: faListNumeric,
      link: "/OurSexplorationList/AnswerQuestions/InOrder/" + spouse,
    },
    {
      type: "bySection",
      name: "By section",
      icon: faListSquares,
      link: "/OurSexplorationList/AnswerQuestions/BySection/none/" + spouse,
    },
    {
      type: "questionsMySpouseHasAnswered",
      name: "Questions my spouse has answered",
      icon: faPersonCircleQuestion,
      link: "/OurSexplorationList/AnswerQuestions/SpouseAnswered/" + spouse,
    },
    {
      type: "questionsAlreadyAnswered",
      name: "Questions I have already answered",
      icon: faCheckCircle,
      link: "/OurSexplorationList/AnswerQuestions/AlreadyAnswered/" + spouse,
    },
  ];

  return (
    <React.Fragment>
      <Header
        breadcrumbs={breadcrumbs}
        title="Answer Questions | Our Sexploration List | Uncovering Intimacy"
        h1={"Answer Questions (" + spouse + ")"}
      >
        <Card>
          <CardHeading>What questions would you like to see?</CardHeading>
          <CardBody>
            {questionTypes.map((questionType) => (
              <LinkButton
                className="block mb-2 text-lg max-w-sm"
                color={spouse.toLowerCase() === "husband" ? "blue" : "pink"}
                key={questionType.type}
                to={questionType.link}
              >
                <FontAwesomeIcon icon={questionType.icon} /> {questionType.name}
              </LinkButton>
            ))}
          </CardBody>
        </Card>
      </Header>
    </React.Fragment>
  );
}
