export const Spectrum = ({
  value,
  leftSideLabel,
  rightSideLabel,
}: {
  value: number;
  leftSideLabel: string;
  rightSideLabel: string;
}) => {
  return (
    <div className="bar">
      <div className="float-start m-2">{leftSideLabel}</div>
      <div className="float-end m-2">{rightSideLabel}</div>
      <div className="value" style={{ width: value.toString() + "%" }}></div>
    </div>
  );
};
