import { Field, useFormikContext } from "formik";
import React from "react";
import { ErrorAlert } from "./alerts";

export type RadioButtonGroupOption = {
  id: string;
  text: string;
  value: string;
  checked: Function;
  onChange?: Function;
};

export type RadioButtonGroupProps = {
  title: string;
  description?: string;
  name: string;
  options: RadioButtonGroupOption[];
};

export function RadioButtonGroup(props: RadioButtonGroupProps) {
  const formik = useFormikContext();
  const { title, description, name, options } = props;

  return (
    <React.Fragment>
      <fieldset className="mt-4">
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          {title}
        </legend>
        {description ? (
          <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>
        ) : null}
        <div className="mt-2 space-y-1">
          {options.map((option) => (
            <div className="flex items-center gap-x-3" key={option.id}>
              <Field
                id={option.id}
                name={name}
                type="radio"
                checked={eval("formik.values." + name) === option.value}
                value={option.value}
                onChange={() => formik.setFieldValue(name, option.value)}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor={option.id}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {option.text}
              </label>
            </div>
          ))}
          {eval("formik.errors." + name) && (
            <ErrorAlert message={eval("formik.errors." + name)} />
          )}
        </div>
      </fieldset>
    </React.Fragment>
  );
}
