import Header from "components/header";
import { useOurSexplorationListAnswers } from "hooks/useOurSexplorationListAnswers";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useState } from "react";
import { Answer, Matches, Responses } from "types/OurSexplorationList";
import { Question } from "types/Questions";
import ShowMatchType from "./_ShowMatchType";

export default function FindMatches() {
  const [open, setOpen] = useState("1");
  const toggle = (tab: string) => {
    if (open !== tab) {
      setOpen(tab);
    } else {
      setOpen("");
    }
  };

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Find Matches",
      link: "/OurSexplorationList/FindMatches",
      active: true,
    },
  ];

  const { questions } = useOurSexplorationListQuestions();
  const { answers } = useOurSexplorationListAnswers();

  const addMatch = (
    list: Responses,
    question: Question,
    pairedQuestion: string | undefined,
    husbandAnswer: Answer,
    wifeAnswer: Answer
  ) => {
    if (list[question.question_type] === undefined) {
      list[question.question_type] = [];
    }
    list[question.question_type].push({
      questionId: question.id,
      paired_questionId: question.paired_question,
      question: question.question,
      paired_question: pairedQuestion,
      husband: husbandAnswer.answer,
      wife: wifeAnswer.answer,
      husband_notes: husbandAnswer.notes,
      wife_notes: wifeAnswer.notes,
    });

    return list;
  };

  let matches: Matches = {
    positive: {},
    potential: {},
    wait: {},
    conflict: {},
    ignore: {},
    error: {},
  };

  if (answers.status === "success" && questions.status === "success") {
    questions.data.forEach((question) => {
      const husbandAnswer = answers.data.find(
        (answer) =>
          answer.question_id == question.id && answer.spouse === "husband"
      );
      const wifeAnswer = answers.data.find((answer) =>
        question.paired_question !== null
          ? answer.question_id == question.paired_question &&
            answer.spouse === "wife"
          : answer.question_id == question.id && answer.spouse === "wife"
      );

      if (husbandAnswer !== undefined && wifeAnswer !== undefined) {
        const paired_question = questions.data.find(
          (q) => q.id == question.paired_question
        )?.question;

        let key = categorizeMatch(husbandAnswer.answer, wifeAnswer.answer);

        if (key !== undefined) {
          matches[key] = addMatch(
            matches[key],
            question,
            paired_question,
            husbandAnswer,
            wifeAnswer
          );
        }
      }
    });
  }

  return (
    <React.Fragment>
      <Header
        title="Our Sexploration List | Uncovering Intimacy"
        h1="Our Sexploration List"
        breadcrumbs={breadcrumbs}
      >
        <ShowMatchType
          matches={matches}
          type="positive"
          heading="Keep doing these things!"
          open={open}
          toggle={toggle}
          color="bg-green-200 border-green-500 border"
        >
          These are activites where you both answered that you liked them. So,
          keep doing them!
        </ShowMatchType>

        <ShowMatchType
          matches={matches}
          type="potential"
          heading="Here are some items to try"
          open={open}
          toggle={toggle}
          color="bg-green-50 border-green-500 border"
        >
          There are activities where at least one spouse was interested in doing
          this, and the other was as well, or they were at least willing to do
          or try it. This also includes items that you are already doing, but at
          least one spouse indicated they'd like more of it.
        </ShowMatchType>

        <ShowMatchType
          matches={matches}
          type="wait"
          heading="Not ready for this yet"
          open={open}
          toggle={toggle}
          color="bg-orange-50 border-orange-500 border"
        >
          There are activities where at least one spouse was interested in doing
          it, but the other wasn't ready yet. It's not a "no", just a "not yet".
        </ShowMatchType>

        <ShowMatchType
          matches={matches}
          type="conflict"
          heading="You should talk about these"
          open={open}
          toggle={toggle}
          color="bg-red-50 border-red-500 border"
        >
          These are items that should be discussed because there is some
          conflict or misunderstanding going on. It could be that one spouse
          wants this activity and the other doesn't. Or you answered in a way
          that didn't seem to make sense (like one saying they wanted more of
          something that you're already doing, but the other says they're
          willing to try it, but haven't yet). In either case, these are items
          that may need some discussion.
        </ShowMatchType>
        <ShowMatchType
          matches={matches}
          type="ignore"
          heading="Ignore"
          open={open}
          toggle={toggle}
          color="bg-grey-50 border-grey-500 border"
        >
          Just leave these alone - you don't even have to look at them. Neither
          spouse is interested in doing these.
        </ShowMatchType>
        <ShowMatchType
          matches={matches}
          type="error"
          heading="Errors"
          open={open}
          toggle={toggle}
          color="bg-red-200 border-red-500 border"
        >
          Something has gone wrong here - probably a programming error. Most
          likely I forgot a permutation of the answers and didn't know how to
          score it. Please send a screenshot to jay@uncoveringintimacy.com so I
          can fix it. Thank you!
        </ShowMatchType>
      </Header>
    </React.Fragment>
  );
}

const categorizeMatch = (husband: string, wife: string) => {
  if (checkPair(husband, wife, "like") || checkPair(husband, wife, "yes")) {
    return "positive";
  } else if (
    checkPair(husband, wife, "more", "like") ||
    checkPair(husband, wife, "more") ||
    checkPair(husband, wife, "want", "willing") ||
    checkPair(husband, wife, "want") ||
    checkPair(husband, wife, "willing") ||
    checkPair(husband, wife, "want", "try") ||
    checkPair(husband, wife, "neutral") ||
    checkPair(husband, wife, "neutral", "willing") ||
    checkPair(husband, wife, "neutral", "try") ||
    checkPair(husband, wife, "neutral", "want") ||
    checkPair(husband, wife, "more", "neutral") ||
    checkPair(husband, wife, "maybe") ||
    checkPair(husband, wife, "turn-on", "arousing") ||
    checkPair(husband, wife, "maybe", "yes") ||
    checkPair(husband, wife, "arousing") ||
    checkPair(husband, wife, "turn-on")
  ) {
    return "potential";
  } else if (
    checkPair(husband, wife, "not-ready") ||
    checkPair(husband, wife, "not-ready", "want") ||
    checkPair(husband, wife, "not-ready", "willing") ||
    checkPair(husband, wife, "not-ready", "neutral") ||
    checkPair(husband, wife, "try") ||
    checkPair(husband, wife, "try", "willing") ||
    checkPair(husband, wife, "like", "neutral") ||
    checkPair(husband, wife, "turn-on", "neutral") ||
    checkPair(husband, wife, "arousing", "neutral")
  ) {
    return "wait";
  } else if (
    checkPair(husband, wife, "not-willing") ||
    checkPair(husband, wife, "not-willing", "willing") ||
    checkPair(husband, wife, "not-willing", "try") ||
    checkPair(husband, wife, "not-willing", "not-ready") ||
    checkPair(husband, wife, "not-willing", "do-not-want") ||
    checkPair(husband, wife, "not-willing", "neutral") ||
    checkPair(husband, wife, "do-not-want", "try") ||
    checkPair(husband, wife, "do-not-want", "willing") ||
    checkPair(husband, wife, "do-not-want") ||
    checkPair(husband, wife, "do-not-want", "not-ready") ||
    checkPair(husband, wife, "try", "not-ready") ||
    checkPair(husband, wife, "maybe", "no") ||
    checkPair(husband, wife, "do-not-want", "neutral") ||
    checkPair(husband, wife, "do-not-care", "neutral") ||
    checkPair(husband, wife, "turn-off", "do-not-care") ||
    checkPair(husband, wife, "shut-down", "do-not-care") ||
    checkPair(husband, wife, "no") ||
    checkPair(husband, wife, "do-not-care")
  ) {
    return "ignore";
  } else if (
    checkPair(husband, wife, "stop") ||
    checkPair(husband, wife, "stop", "not-ready") ||
    checkPair(husband, wife, "stop", "try") ||
    checkPair(husband, wife, "like", "not-ready") ||
    checkPair(husband, wife, "like", "not-willing") ||
    checkPair(husband, wife, "like", "try") ||
    checkPair(husband, wife, "like", "want") ||
    checkPair(husband, wife, "like", "do-not-want") ||
    checkPair(husband, wife, "like", "stop") ||
    checkPair(husband, wife, "like", "willing") ||
    checkPair(husband, wife, "more", "not-ready") ||
    checkPair(husband, wife, "more", "not-willing") ||
    checkPair(husband, wife, "more", "try") ||
    checkPair(husband, wife, "more", "willing") ||
    checkPair(husband, wife, "more", "want") ||
    checkPair(husband, wife, "more", "do-not-want") ||
    checkPair(husband, wife, "more", "stop") ||
    checkPair(husband, wife, "do-not-want", "want") ||
    checkPair(husband, wife, "willing", "stop") ||
    checkPair(husband, wife, "want", "stop") ||
    checkPair(husband, wife, "stop", "do-not-want") ||
    checkPair(husband, wife, "stop", "neutral") ||
    checkPair(husband, wife, "yes", "no") ||
    checkPair(husband, wife, "arousing", "do-not-care") ||
    checkPair(husband, wife, "arousing", "turn-off") ||
    checkPair(husband, wife, "turn-on", "do-not-care") ||
    checkPair(husband, wife, "neutral", "shut-down") ||
    checkPair(husband, wife, "neutral", "turn-off") ||
    checkPair(husband, wife, "neutral", "do-not-care")
  ) {
    return "conflict";
  }

  return "error";
};

const checkPair = (
  husband: string,
  wife: string,
  match1: string,
  match2: string | undefined = undefined
) => {
  if (match2 === undefined) match2 = match1;

  if (
    (husband == match1 && wife == match2) ||
    (husband == match2 && wife == match1)
  ) {
    return true;
  } else {
    return false;
  }
};
