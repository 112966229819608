import { UserContext } from "App";
import { gql, GraphQLClient } from "graphql-request";
import { useContext } from "react";
import { useQuery } from "react-query";
import { APIResponse } from "types/APIResponse";
import { Session } from "types/Sessions";
import { useAuth } from "./useAuth";

export function useSessions() {
  const user = useContext(UserContext);
  const { logout } = useAuth();

  const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_URL + "/graphql/oursexplorationlist",
    {
      headers: {
        authorization: "JWT " + user.token,
      },
    }
  );

  const sessions = useQuery(["sessions", user.ID], async () => {
    if (user.ID !== 0) {
      const query = gql`
        query getMySessions($user_id: Int!) {
          getMySessions(user_id: $user_id) {
            id
            user_id
            notes
            husband_status
            wife_status
            created_at
            updated_at
            status
          }
        }
      `;

      try {
        const result: any = await graphQLClient.request(query, {
          user_id: user.ID,
        });

        const getMySessions = result.getMySessions as Session[];

        return getMySessions;
      } catch (e) {
        return [] as Session[];
      }
    } else {
      return [] as Session[];
    }
  });

  const deleteSession = async (id: number) => {
    const query = gql`
      mutation deleteSession($id: ID!) {
        deleteSession(id: $id) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { deleteSession }: { deleteSession: Response } =
      await graphQLClient.request(query, { id });

    sessions.refetch();

    return deleteSession;
  };

  const archiveSession = async (id: number) => {
    const query = gql`
      mutation updateSession($id: ID!) {
        updateSession(id: $id, status: "archived") {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { archiveSession }: { archiveSession: Response } =
      await graphQLClient.request(query, { id });
    sessions.refetch();

    return archiveSession;
  };

  const unarchiveSession = async (id: number) => {
    const query = gql`
      mutation updateSession($id: ID!) {
        updateSession(id: $id, status: "active") {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { unarchiveSession }: { unarchiveSession: Response } =
      await graphQLClient.request(query, { id });
    sessions.refetch();

    return unarchiveSession;
  };

  const getSessionById = (id: number) => {
    if (sessions.isSuccess) {
      const session = sessions.data.find((session) => session.id == id); // eslint-disable-line
      return session;
    }
  };

  const createSession = async (notes: string) => {
    const query = gql`
      mutation createSession($user_id: Int!, $notes: String) {
        createSession(user_id: $user_id, notes: $notes) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { createSession }: { createSession: APIResponse } =
      await graphQLClient.request(query, {
        user_id: user.ID,
        notes,
      });
    sessions.refetch();

    return createSession;
  };

  const updateSessionNotes = async (id: number, notes: string) => {
    const query = gql`
      mutation updateSession($id: ID!, $notes: String) {
        updateSession(id: $id, notes: $notes) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { updateSessionNotes }: { updateSessionNotes: Response } =
      await graphQLClient.request(query, { id, notes });
    sessions.refetch();

    return updateSessionNotes;
  };

  const getBadgeColor = (status: string) => {
    switch (status) {
      case "not_started":
        return "red";
      case "in_progress":
        return "yellow";
      case "completed":
        return "green";
      default:
        return "grey";
    }
  };

  const makeStatusReadable = (status: string) => {
    switch (status) {
      case "not_started":
        return "Not Started";
      case "in_progress":
        return "In Progress";
      case "completed":
        return "Completed";
      default:
        return status + " complete";
    }
  };

  return {
    sessions,
    getSessionById,
    deleteSession,
    archiveSession,
    unarchiveSession,
    createSession,
    updateSessionNotes,
    getBadgeColor,
    makeStatusReadable,
  };
}
