import Header from "components/header";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { Question, QuestionType, question_types } from "types/Questions";
import { getQuestionOutput } from "./_Functions";
import { useAuth } from "hooks/useAuth";
import LinkButton from "components/linkbutton";
import Card, { CardBody, CardHeading } from "components/card";

export default function QuestionsBySection(props: any) {
  const { logout } = useAuth();
  const params = useParams();
  const spouse: string = params.spouse ?? "";
  const section: string = params.section ?? "none";
  const [question, setQuestion] = React.useState<Question | undefined>(
    undefined
  );
  const { unansweredQuestions } = useOurSexplorationListQuestions(
    spouse.toLowerCase()
  );
  const [numberOfQuestions, setNumberOfQuestions] = React.useState<number>(0);

  const breadcrumbs = [
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: false,
    },
    {
      name: "Questions by Section",
      link: "/OurSexplorationList/AnswerQuestion/BySection/none/" + spouse,
      active: true,
    },
  ];

  const getTypesArray = useCallback(
    (types: QuestionType[], section: string, force: boolean) => {
      let typesArray: string[] = [];

      types.forEach((type) => {
        if (type.value === section || force) {
          typesArray.push(type.value);
        }

        if (type.subTypes) {
          typesArray = typesArray.concat(
            getTypesArray(type.subTypes, section, type.value === section)
          );
        }
      });

      return typesArray;
    },
    []
  );

  useEffect(() => {
    if (unansweredQuestions.isSuccess) {
      let typesArray = getTypesArray(question_types, section, false);

      let questions = unansweredQuestions.data?.filter((question) => {
        return typesArray.includes(question.question_type);
      });
      setNumberOfQuestions(questions?.length || 0);

      setQuestion(questions[0]);
    }
  }, [unansweredQuestions, getTypesArray, section]);

  const refetch = async () => {
    await unansweredQuestions.refetch();
  };

  function outputTypes(question_types: QuestionType[], level: number) {
    return (
      <ul className="list-inside">
        {question_types.map((question_type) => {
          return (
            <React.Fragment key={question_type.value}>
              <li style={{ textIndent: level * 32 + "px" }}>
                <LinkButton
                  className="block mb-2 text-lg"
                  color={spouse.toLowerCase() === "husband" ? "blue" : "pink"}
                  key={question_type.value}
                  to={
                    "/OurSexplorationList/AnswerQuestions/BySection/" +
                    question_type.value +
                    "/" +
                    spouse
                  }
                >
                  {question_type.label}
                </LinkButton>
                {question_type.subTypes
                  ? outputTypes(question_type.subTypes, level + 1)
                  : ""}
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    );
  }

  if (section === "none") {
    return (
      <React.Fragment>
        <Header
          title="Questions in order | Our Sexploration List | Uncovering Intimacy"
          h1="Questions in order"
          breadcrumbs={breadcrumbs}
        >
          <Card>
            <CardHeading>
              What section would you like to answer questions from?
            </CardHeading>
            <CardBody>{outputTypes(question_types, 0)}</CardBody>
          </Card>
        </Header>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header
        title="Questions in order | Our Sexploration List | Uncovering Intimacy"
        h1="Questions in order"
        breadcrumbs={breadcrumbs}
      >
        {getQuestionOutput(
          question,
          spouse,
          refetch,
          unansweredQuestions,
          logout,
          numberOfQuestions + " questions remaining in this section"
        )}
      </Header>
    </React.Fragment>
  );
}
