export function getColors(color?: string | "gray") {
  let bgColor = "";
  let hoverColor = "";
  let ringColor = "";
  let lightColor = "";
  let darkTextColor = "";
  let textColor = "";

  switch (color) {
    case "orange":
      bgColor = "bg-orange-600";
      darkTextColor = "text-orange-600";
      hoverColor = "hover:bg-orange-500";
      lightColor = "bg-orange-50";
      ringColor = "ring-orange-500/10";
      textColor = "text-orange-50";
      break;
    case "red":
      bgColor = "bg-red-600";
      darkTextColor = "text-red-600";
      hoverColor = "hover:bg-red-500";
      lightColor = "bg-red-50";
      ringColor = "ring-red-500/10";
      textColor = "text-red-50";
      break;
    case "yellow":
      bgColor = "bg-yellow-600";
      darkTextColor = "text-yellow-600";
      hoverColor = "hover:bg-yellow-500";
      lightColor = "bg-yellow-50";
      ringColor = "ring-yellow-500/10";
      textColor = "text-yellow-50";
      break;
    case "green":
      bgColor = "bg-green-600";
      darkTextColor = "text-green-600";
      hoverColor = "hover:bg-green-500";
      lightColor = "bg-green-50";
      ringColor = "ring-green-500/10";
      textColor = "text-green-50";
      break;
    case "blue":
      bgColor = "bg-blue-600";
      darkTextColor = "text-blue-600";
      hoverColor = "hover:bg-blue-500";
      lightColor = "bg-blue-50";
      ringColor = "ring-blue-500/10";
      textColor = "text-blue-50";
      break;
    case "indigo":
      bgColor = "bg-indigo-600";
      darkTextColor = "text-indigo-600";
      hoverColor = "hover:bg-indigo-500";
      lightColor = "bg-indigo-50";
      ringColor = "ring-indigo-500/10";
      textColor = "text-indigo-50";
      break;
    case "purple":
      bgColor = "bg-purple-600";
      darkTextColor = "text-purple-600";
      hoverColor = "hover:bg-purple-500";
      lightColor = "bg-purple-50";
      ringColor = "ring-purple-500/10";
      textColor = "text-purple-50";
      break;
    case "pink":
      bgColor = "bg-pink-600";
      darkTextColor = "text-pink-600";
      hoverColor = "hover:bg-pink-500";
      lightColor = "bg-pink-50";
      textColor = "text-pink-50";
      ringColor = "ring-pink-500/10";
      break;
    default:
      bgColor = "bg-gray-600";
      darkTextColor = "text-gray-600";
      hoverColor = "hover:bg-gray-500";
      lightColor = "bg-gray-50";
      textColor = "text-gray-50";
      ringColor = "ring-gray-500/10";
      break;
  }

  return {
    bgColor,
    hoverColor,
    lightColor,
    ringColor,
    darkTextColor,
    textColor,
  };
}

export function getDaysDifferenceFromDate(
  startDate: Date,
  endDate: Date = new Date()
) {
  const targetDate = new Date(startDate);

  // To calculate the time difference of two dates
  const timeDifference = endDate.getTime() - targetDate.getTime();

  // To calculate the number of days between two dates
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference;
}
