import { UserContext } from "App";
import { gql, GraphQLClient } from "graphql-request";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { APIResponse } from "types/APIResponse";
import { Answer } from "types/OurSexplorationList";

export function useOurSexplorationListAnswers() {
  const user = useContext(UserContext);
  const queryClient = useQueryClient();

  const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_URL + "/graphql/oursexplorationlist",
    {
      headers: {
        authorization: "JWT " + user.token,
      },
    }
  );

  const answers = useQuery(
    ["answers"],
    async () => {
      const query = gql`
        query getSessionAnswers($user_id: ID!, $session_id: ID!) {
          getSessionAnswers(user_id: $user_id, session_id: $session_id) {
            id
            question_id
            user_id
            answer
            created_at
            updated_at
            session_id
            spouse
            notes
          }
        }
      `;
      const { getSessionAnswers }: { getSessionAnswers: Answer[] } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          session_id: user.oursexplorationlist_current_session_id,
        });

      return getSessionAnswers;
    },
    {
      enabled:
        user.oursexplorationlist_current_session_id !== null &&
        user.ID !== null,
    }
  );

  const createAnswer = async (
    question_id: number,
    answer: string,
    spouse: string,
    notes: string
  ) => {
    const existingAnswer = answers.data?.find(
      (answer) =>
        answer.question_id === question_id &&
        answer.spouse === spouse &&
        answer.session_id === user.oursexplorationlist_current_session_id
    );
    if (existingAnswer) {
      return updateAnswer(existingAnswer.id, answer, notes);
    } else {
      const query = gql`
        mutation createAnswer(
          $question_id: Int!
          $answer: String!
          $user_id: Int!
          $session_id: Int!
          $spouse: String!
          $notes: String!
        ) {
          createAnswer(
            question_id: $question_id
            answer: $answer
            user_id: $user_id
            session_id: $session_id
            spouse: $spouse
            notes: $notes
          ) {
            success
            message
            affectedRows
            id
          }
        }
      `;

      const { createAnswer }: { createAnswer: APIResponse } =
        await graphQLClient.request(query, {
          question_id,
          answer,
          user_id: user.ID,
          session_id: user.oursexplorationlist_current_session_id,
          spouse,
          notes,
        });

      queryClient.invalidateQueries(["answers", "unansweredQuestions"]);

      return createAnswer;
    }
  };

  const updateAnswer = async (id: number, answer: string, notes: string) => {
    const query = gql`
      mutation updateAnswer($id: ID!, $answer: String!, $notes: String!) {
        updateAnswer(id: $id, answer: $answer, notes: $notes) {
          success
          message
          affectedRows
        }
      }
    `;
    const { updateAnswer }: { updateAnswer: APIResponse } =
      await graphQLClient.request(query, {
        id,
        answer,
        notes,
      });

    queryClient.invalidateQueries(["answers", "unansweredQuestions"]);

    return updateAnswer;
  };

  return { answers, createAnswer };
}
