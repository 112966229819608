import { gql, GraphQLClient } from "graphql-request";
import { useQuery } from "react-query";
import { Question } from "types/Questions";
import { APIResponse } from "types/APIResponse";
import { UserContext } from "App";
import { useContext } from "react";

export function useOurSexplorationListQuestions(spouse?: string) {
  spouse = spouse?.toLowerCase();
  const user = useContext(UserContext);
  const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_URL + "/graphql/oursexplorationlist",
    {
      headers: {
        authorization: "JWT " + user.token,
      },
    }
  );

  const questions = useQuery(["questions"], async () => {
    const query = gql`
      query getAllQuestions {
        getAllQuestions {
          id
          question
          tags
          created_at
          active
          paired_question
          question_type
          more_info_label
          more_info_url
          gender_specific
        }
      }
    `;

    const { getAllQuestions }: { getAllQuestions: Question[] } =
      await graphQLClient.request(query);

    return getAllQuestions;
  });

  const questionsCount = questions.data?.length;

  const unansweredQuestions = useQuery(
    ["questions", "unansweredQuestions"],
    async () => {
      const query = gql`
        query getUnansweredQuestions(
          $user_id: ID!
          $session_id: ID!
          $spouse: String
        ) {
          getUnansweredQuestions(
            user_id: $user_id
            session_id: $session_id
            spouse: $spouse
          ) {
            id
            question
            tags
            created_at
            active
            paired_question
            question_type
            more_info_label
            more_info_url
            gender_specific
          }
        }
      `;
      const { getUnansweredQuestions }: { getUnansweredQuestions: Question[] } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          session_id: user.oursexplorationlist_current_session_id,
          spouse: spouse,
        });

      return getUnansweredQuestions;
    },
    {
      enabled: user.ID !== 0 && spouse !== undefined,
    }
  );

  const answeredQuestions = useQuery(
    ["questions", "answeredQuestions"],
    async () => {
      const query = gql`
        query getAnsweredQuestions(
          $user_id: ID!
          $session_id: ID!
          $spouse: String
        ) {
          getAnsweredQuestions(
            user_id: $user_id
            session_id: $session_id
            spouse: $spouse
          ) {
            id
            question
            tags
            created_at
            active
            paired_question
            question_type
            more_info_label
            more_info_url
          }
        }
      `;
      const { getAnsweredQuestions }: { getAnsweredQuestions: Question[] } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          session_id: user.oursexplorationlist_current_session_id,
          spouse: spouse,
        });

      return getAnsweredQuestions;
    },
    {
      enabled: user.ID !== 0 && spouse !== undefined,
    }
  );

  const unansweredQuestionsCount = unansweredQuestions.data?.length;

  const unansweredQuestionsSpouseAnswered = useQuery(
    ["questions", "unansweredQuestionsSpouseAnswered"],
    async () => {
      const query = gql`
        query getUnansweredQuestionsSpouseAnswered(
          $user_id: ID!
          $session_id: ID!
          $spouse: String
        ) {
          getUnansweredQuestionsSpouseAnswered(
            user_id: $user_id
            session_id: $session_id
            spouse: $spouse
          ) {
            id
            question
            tags
            created_at
            active
            paired_question
            question_type
            more_info_label
            more_info_url
            gender_specific
          }
        }
      `;
      const {
        getUnansweredQuestionsSpouseAnswered,
      }: { getUnansweredQuestionsSpouseAnswered: Question[] } =
        await graphQLClient.request(query, {
          user_id: user.ID,
          session_id: user.oursexplorationlist_current_session_id,
          spouse: spouse,
        });

      return getUnansweredQuestionsSpouseAnswered;
    },
    {
      enabled: user.ID !== 0 && spouse !== undefined,
    }
  );

  const createQuestion = async (
    question: string,
    tags: string,
    active: boolean,
    paired_question: number | null,
    question_type: string,
    more_info_label: string | null,
    more_info_url: string | null,
    gender_specific: string
  ) => {
    const query = gql`
      mutation createQuestion(
        $question: String!
        $tags: String!
        $active: Boolean!
        $paired_question: Int
        $question_type: String!
        $more_info_label: String
        $more_info_url: String
        $gender_specific: String
      ) {
        createQuestion(
          question: $question
          tags: $tags
          active: $active
          paired_question: $paired_question
          question_type: $question_type
          more_info_label: $more_info_label
          more_info_url: $more_info_url
          gender_specific: $gender_specific
        ) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { createQuestion }: { createQuestion: APIResponse } =
      await graphQLClient.request(query, {
        question,
        tags,
        active,
        paired_question,
        question_type,
        more_info_label,
        more_info_url,
        gender_specific,
      });
    questions.refetch();

    return createQuestion;
  };

  const deleteQuestion = async (id: number) => {
    const query = gql`
      mutation deleteQuestion($id: ID!) {
        deleteQuestion(id: $id) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { deleteQuestion }: { deleteQuestion: APIResponse } =
      await graphQLClient.request(query, { id });
    questions.refetch();

    return deleteQuestion;
  };

  const pairQuestion = async (id: number, paired_question: number) => {
    const query = gql`
      mutation pairQuestion($id: ID!, $paired_question: Int!) {
        pairQuestion(id: $id, paired_question: $paired_question) {
          success
          message
          affectedRows
          id
        }
      }
    `;
    const { pairQuestion }: { pairQuestion: APIResponse } =
      await graphQLClient.request(query, {
        id,
        paired_question,
      });
    questions.refetch();

    return pairQuestion;
  };

  const refetch = () => {
    questions.refetch();
  };

  return {
    questions,
    createQuestion,
    deleteQuestion,
    pairQuestion,
    unansweredQuestions,
    unansweredQuestionsSpouseAnswered,
    questionsCount,
    unansweredQuestionsCount,
    answeredQuestions,
    refetch,
  };
}
