import React from "react";
import { Link } from "react-router-dom";
import { getColors } from "./_globalFunctions";

export default function LinkButton(props: {
  children: React.ReactNode;
  to: string;
  className?: string | "";
  color?: string | "grey";
}) {
  let { bgColor, hoverColor } = getColors(props.color);

  return (
    <Link
      to={props.to}
      className={
        "rounded " +
        bgColor +
        " px-2 py-1 font-semibold text-white shadow-sm hover:" +
        hoverColor +
        " focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:" +
        bgColor +
        " whitespace-nowrap " +
        props.className
      }
    >
      {props.children}
    </Link>
  );
}
