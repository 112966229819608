import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { Button, Spinner } from "reactstrap";
import { useAuth } from "../hooks/useAuth";
import horizontalLogo from "../assets/images/logo.png";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

export default function Login() {
  const [loggingIn, setLoggingIn] = React.useState(false);
  const { login, isLoggedIn, loginError } = useAuth();

  const loginToWordPress = useCallback(
    async (e: any) => {
      const username = e["username"];
      const password = e["password"];

      setLoggingIn(true);

      const response = await login(username, password);
      if (response) {
        window.location.href = "/";
      }

      setLoggingIn(false);
    },
    [login]
  );

  document.title = "Login | Uncovering Intimacy";

  return (
    <React.Fragment>
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={loginToWordPress}
      >
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="mx-auto h-10 w-auto"
                  src={horizontalLogo}
                  alt="Your Company"
                />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
              </div>
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username
                  </label>
                  <div className="mt-2">
                    <Field
                      id="username"
                      name="username"
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <a
                      href="https://www.uncoveringintimacy.com/my-account/lost-password/"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div>
                  <Button
                    disabled={loggingIn}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {loggingIn ? (
                      <Spinner size="sm">Logging in...</Spinner>
                    ) : (
                      "Log in"
                    )}
                  </Button>
                </div>
              </Form>

              {loginError !== "" && !loggingIn ? (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Login failed - incorrect username or password
                      </h3>
                    </div>
                  </div>
                </div>
              ) : null}
              {isLoggedIn ? (
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        Order completed
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p>Login successful!</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Formik>
    </React.Fragment>
  );
}
