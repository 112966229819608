import Header from "components/header";
import { Field, Form, Formik, FormikValues } from "formik";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";

export default function Settings(props: any) {
  const { user, updateUserSettings, refreshLocalData } = useAuth();
  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Settings",
      link: "/Settings",
      active: true,
    },
  ];

  const topics = {
    anal: "Anal",
    bdsm: "BDSM",
    oral: "Oral",
    menstruation: "Menstruation",
    tickling: "Tickling",
    wrestling: "Wrestling",
    massage: "Massage",
    grooming: "Grooming",
    stripping: "Stripping",
    media: "Media",
    manual: "Manual",
    nipple: "Nipple",
    food: "Food",
    dirtytalk: "Dirty Talk",
    prostate: "Prostate",
    positions: "Positions",
    foreplay: "Foreplay",
    toys: "Toys",
  };

  async function saveSettings(values: FormikValues) {
    const excluded_topics: string[] = values.topics;
    const oursexplorationlist_simplified_version: number = parseInt(
      values.oursexplorationlist_simplified_version
    );
    const result = await updateUserSettings(
      excluded_topics.join(","),
      oursexplorationlist_simplified_version
    );
    if (result.success) {
      toast("Settings updated successfully", { type: "success" });
      refreshLocalData();
    } else {
      toast("Error updating settings", { type: "error" });
    }
  }

  if (!user) {
    return <React.Fragment>Loading...</React.Fragment>;
  }

  return (
    <React.Fragment>
      <Header
        title="Settings | Uncovering Intimacy"
        h1="Settings"
        breadcrumbs={breadcrumbs}
      >
        <Formik
          initialValues={{
            topics: (user.excluded_topics as string).split(","),
            oursexplorationlist_simplified_version:
              user.oursexplorationlist_simplified_version,
          }}
          onSubmit={saveSettings}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Row>
                  <Col>
                    <h2>Topics to exclude</h2>
                    {Object.entries(topics).map(([key, value]) => {
                      return (
                        <React.Fragment key={key}>
                          <div>
                            <Field
                              type="checkbox"
                              name="topics"
                              value={key}
                              id={key}
                            />
                            <label htmlFor={key} className="ms-1">
                              {value}
                            </label>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </Col>
                  <Col>
                    <h2>Our Sexploration List</h2>
                    <p>
                      <Field
                        type="radio"
                        name="oursexplorationlist_simplified_version"
                        value="1"
                        id="oursexplorationlist_simplified_version_1"
                        checked={
                          values.oursexplorationlist_simplified_version == "1"
                        }
                      />
                      <label
                        htmlFor="oursexplorationlist_simplified_version_1"
                        className="ms-1"
                      >
                        Simplified answers
                      </label>
                      <br />
                      <Field
                        type="radio"
                        name="oursexplorationlist_simplified_version"
                        value="0"
                        id="oursexplorationlist_simplified_version_0"
                        checked={
                          values.oursexplorationlist_simplified_version == "0"
                        }
                      />
                      <label
                        htmlFor="oursexplorationlist_simplified_version_0"
                        className="ms-1"
                      >
                        Expanded answers
                      </label>
                    </p>
                  </Col>
                </Row>

                <Button type="submit">Save</Button>
              </Form>
            );
          }}
        </Formik>
      </Header>
    </React.Fragment>
  );
}
