import { UserContext } from "App";
import Header from "components/header";
import { useOurSexplorationListQuestions } from "hooks/useOurSexplorationListQuestions";
import { useSessions } from "hooks/useOurSexplorationListSessions";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Question } from "types/Questions";
import { otherSpouse } from "./_Functions";
import ShowQuestion from "./_ShowQuestion";

export default function QuestionsSpouseAnswered(props: any) {
  const params = useParams();
  const spouse: string = params.spouse || "";
  const [question, setQuestion] = React.useState<Question | undefined>(
    undefined
  );
  const { getSessionById } = useSessions();
  const { unansweredQuestionsSpouseAnswered } =
    useOurSexplorationListQuestions(spouse);
  const user = useContext(UserContext);
  const session_id = user.oursexplorationlist_current_session_id;
  const session = getSessionById(session_id);
  let spouseStatus = "";
  if (session !== undefined) {
    if (spouse.toLowerCase() === "wife") {
      spouseStatus = session.husband_status;
    } else {
      spouseStatus = session.wife_status;
    }
  }

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Our Sexploration List",
      link: "/OurSexplorationList",
      active: false,
    },
    {
      name: "Answer Questions",
      link: "/OurSexplorationList/AnswerQuestions/" + spouse,
      active: false,
    },
    {
      name: "Questions spouse answered",
      link: "/OurSexplorationList/AnswerQuestions/SpouseAnswered/" + spouse,
      active: true,
    },
  ];

  useEffect(() => {
    if (unansweredQuestionsSpouseAnswered.isSuccess) {
      setQuestion(
        unansweredQuestionsSpouseAnswered.data[
          Math.floor(
            Math.random() * unansweredQuestionsSpouseAnswered.data.length
          )
        ]
      );
    }
  }, [unansweredQuestionsSpouseAnswered.data]);

  const getOutput = () => {
    if (question === undefined) {
      if (spouseStatus === "100%") {
        return (
          <React.Fragment>
            <p>
              You and your {otherSpouse(spouse)} have answered all the available
              questions. The next step is to review your answers and discuss
              them together.
            </p>
            <Link
              to="/OurSexplorationList/FindMatches"
              className="btn btn-primary"
            >
              Find Matches
            </Link>
          </React.Fragment>
        );
      } else if (spouseStatus === "not_started") {
        return (
          <React.Fragment>
            It looks like your {otherSpouse(spouse)} hasn't started the list
            yet. If you haven't already, send them a link to the app and let
            them know that you're ready to start exploring together. Once
            they've started filling out their answers, you can come back to view
            your matches.
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <p>There are no more questions for you to answer.</p>
            <p>
              Check back later to see if your {otherSpouse(spouse)} has answered
              any more questions.
            </p>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <ShowQuestion
            question={question}
            spouse={spouse}
            refetch={unansweredQuestionsSpouseAnswered.refetch}
          />
          <p className="mt-2">
            {unansweredQuestionsSpouseAnswered.data?.length +
              " questions remaining"}
          </p>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Header
        title="Questions spouse answered | Our Sexploration List | Uncovering Intimacy"
        h1="Questions spouse answered"
        breadcrumbs={breadcrumbs}
      >
        {getOutput()}
      </Header>
    </React.Fragment>
  );
}
